const Slider = ({ id, onChange, value, min = 0, max = 100 }) => {

    console.log((((+value - min) * 100) / (max - min)) + "% ");
    console.log(Math.round(+value));


    return (
        <input
            id={id}
            type="range"
            value={+value}
            style={{
                transition: "0s",
                backgroundSize: (((Math.round(+value) - min) * 100) / (max - min)) + "% 100%",
            }}
            min={min}
            max={max}
            onChange={onChange}
        />
    );
};

export default Slider;
