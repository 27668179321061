const CustomDate = ({ updateSDate, updateLDate }) => {
  let date_format = new Date();
  let date =
    date_format.getMonth() +
    1 +
    "-" +
    date_format.getDate() +
    "-" +
    date_format.getFullYear();
  return (
    <div className="custome_dates mb-5">
      <div className="row">
        <div className="col-xl-1 col-sm-2 mb-xl-0 mb-4">
          <p className="date_title">Custom</p>
        </div>
        <div className="col-xl-2 col-sm-5 mb-xl-0 mb-4">
          <input
            className="form-control"
            type="date"
            defaultValue={date}
            onChange={updateSDate}
          />
        </div>
        <div className="col-xl-2 col-sm-5 mb-xl-0 mb-4">
          <input
            className="form-control"
            type="date"
            defaultValue={date}
            onChange={updateLDate}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomDate;
