import axios from "axios";
import { baseURL } from "../utils/urls";
import { authStorage } from "../utils/login";
import { USER_DATA } from "../utils/CONSTANTS";

export const Services = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + authStorage.authToken,
  },
});

Services.interceptors.request.use(
  (req) => {
    // let index = req.url;
    // //console.log({ data: req.data });
    const index = req.url.indexOf("v1") + 2;
    const lastIndex = req.url.indexOf("?");
    let reqEndPoint;
    if (lastIndex === -1) {
      reqEndPoint = req.url.slice(index);
    } else {
      reqEndPoint = req.url.slice(index, lastIndex);
    }

    const endPoints = [
      "/coupons/unused",
      "/coupons/count",
      "/transctions/analytics",
      "/transctions/return-cust",
      "/coupons/logos",
    ];
    //console.log({ reqEndPoint, endPoints });
    if (
      req.method === "get" &&
      (endPoints.indexOf(reqEndPoint) !== -1 ||
        endPoints.indexOf(req.url) !== -1)
    ) {
      let url = req.url;
      if (lastIndex === -1) {
        if (authStorage.role !== "merchant") {
          url += `/${authStorage.mid}`;
        } else {
          url += `/${USER_DATA.user.mid}`;
        }
      } else {
        if (authStorage.role !== "merchant") {
          url =
            req.baseURL +
            reqEndPoint +
            "/" +
            authStorage.mid +
            req.url.slice(lastIndex);
        } else {
          url =
            req.baseURL +
            reqEndPoint +
            +"/" +
            USER_DATA.user.mid +
            req.url.slice(lastIndex);
        }
      }

      req.url = url;
    }
    if (authStorage.role !== "merchant") {

      if (reqEndPoint === "coupons/logos" && ["patch", "put"].includes(req.method)) {
        let data = req.data;
        data.set("mid", authStorage.mid);
        data.set("access_token", authStorage.mToken);
        req.data = data;
      } else {
        let data = {
          ...req.data,
          mid: authStorage.mid,
          access_token: authStorage.mToken,
        };
        req.data = data;
      }

    }
    //console.log({ req });
    return req;
  },
  (err) => {
    return Promise.reject(err);
  },
);
