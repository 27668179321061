import PageHeader from "../../../components/page-header";
import { useContext, useEffect, useState } from "react";
import {
  GetLogo,
  sentCouponCode,
  UpDateLogo,
  updateProfile,
} from "../../../api/allServices";
import clover_paybyCash from "../../../assets/img/clover_paybyCash.png";
import clover_paybyCashorCard from "../../../assets/img/clover_paybyCashorCard.png";
import "react-phone-input-2/lib/style.css";
import { API_ROUTE, CONSTANTS, USER_DATA } from "../../../utils/CONSTANTS";
import useAPI from "../../../CustomeHooks/API";
import { LinearProgress } from "@mui/material";
import { NavConstant } from "../../../store/context";

const lableFontStyle = { fontSize: "1.2rem", color: "#31812F" };
const boldSpan = { fontWeight: "800" };

const CashOrCard = () => {
  const [selectedState, setSelectedState] = useState(
    USER_DATA?.user?.opt_in_double_discount ? 1 : 2
  );
  const getMyProfile = useAPI(API_ROUTE.getMe);
  const [Loading, setLoading] = useState(false);

  const Context = useContext(NavConstant);
  const updatePaymentType = (count) => {
    setSelectedState(count);
  };

  const updateProfileStatus = () => {
    console.log("APICAll");
    (async () => {
      const payload = {
        opt_in_double_discount: selectedState == 1 ? true : false,
      };
      try {
        setLoading(true);
        const res = await updateProfile(payload);
        if (res?.status == "success") {
          USER_DATA.user.opt_in_double_discount =
            selectedState == 1 ? true : false;
        }
        Context.setIsErrorNotification({
          type: true,
          message: "Coupon Data Updated",
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  };

  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader destination="Logo" heading={CONSTANTS.CARD_COUPPON_TITLE} />
      {Loading && (
        <LinearProgress color="inherit" sx={{ margin: "0px 10px" }} />
      )}
      <div className="container-fluid py-4">
        <div className="row content-wrapper">
          <div className="col-lg-7 col-md-7 col-sm-12 my-3">
            <h3 className="fst-italic">
              {CONSTANTS.CARD_COUPPON_HEADING_TEXT}
            </h3>
            <div className="setup-form mt-3"></div>

            <h6>{CONSTANTS.CARD_COUPPON_SUBLINE_TEXT}</h6>
            <p style={{ textAlign: "center" }} className="mt-5">
              {CONSTANTS.CARD_COUPPON_OPTION_TEXT_1}
            </p>
            <p style={{ textAlign: "center" }}>
              {CONSTANTS.CARD_COUPPON_OPTION_TEXT_2}
            </p>
            <p style={{ textAlign: "center" }} className="mb-5">
              {CONSTANTS.CARD_COUPPON_OPTION_TEXT_3}
            </p>

            <div
              class="form-check"
              style={{ display: "flex", marginTop: "105px" }}
            >
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                id="flexCheckDefault"
                style={{ height: "30px", width: "30px", minWidth: "30px" }}
                checked={selectedState == 1}
                onChange={(e) => {
                  if (e.target.value == "1") updatePaymentType(1);
                }}
              />
              <label
                style={lableFontStyle}
                class="form-check-label"
                for="flexCheckDefault"
              >
                <span style={boldSpan}>Cash Discounts:</span> Yes, offer a
                Mobile Coupon with Double Cash Discounts to customers who
                <span style={boldSpan}>Pay by Cash on next visit.</span>
              </label>
            </div>
            <p class="my-3">Or</p>
            <div class="form-check" style={{ display: "flex" }}>
              <input
                class="form-check-input"
                type="checkbox"
                value="2"
                id="flexCheckChecked"
                style={{ height: "30px", width: "30px", minWidth: "30px" }}
                checked={selectedState == 2}
                onChange={(e) => {
                  console.log(e.target.value);
                  if (e.target.value == "2") updatePaymentType(2);
                }}
              />
              <label
                style={lableFontStyle}
                class="form-check-label"
                for="flexCheckChecked"
              >
                <span style={boldSpan}>Cash and Card Discounts:</span> Yes,
                offer a Mobile Coupon with Double Discounts to customers who
                <span style={boldSpan}>
                  Pay by Cash or Card on next visit.
                </span>{" "}
                I understand additional discounts may affect my profit
              </label>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 my-3">
            <img
              style={{ width: "100%" }}
              src={
                selectedState == 1 ? clover_paybyCash : clover_paybyCashorCard
              }
              alt="Clover"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "100px",
              }}
            >
              <button
                className="btn"
                style={{
                  cursor: "pointer",
                  background: "#1D94E6",
                  color: "#fff",
                  fontSize: "15px",
                }}
                onClick={updateProfileStatus}
              >
                Sync Discount
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashOrCard;
