import React from "react";

const InsertWrapper = ({ children, preview }) => {
  if (!preview) {
    return <div className="h-100">{children}</div>;
  }

  return <div className="ba h-100 printable insertWrapper">{children}</div>;
};

export const BlankInsert = () => <React.Fragment>&nbsp;</React.Fragment>;

export const Label = ({
  children,

  preview = true,

  width,
}) => (
  <div className="d-flex printable" style={{ margin: "0.125em", width: width }}>
    <InsertWrapper preview={preview}>{children}</InsertWrapper>
  </div>
);

export const LabelRow = ({ height, children }) => (
  <div
    className="cf:after d-flex flex-wrap printable"
    style={{ height: height }}
  >
    {children}
  </div>
);

export default Label;
