import React, { Fragment, useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import LeftNavigationBar from "../leftNavigationBar/left-navigation-bar";
import Login from "../../pages/auth/Login";
import { authStorage } from "../../utils/login";
import { NavConstant } from "../../store/context";
import AllRoutes from "../../routes/routes";
import Notification from "../Ui/notification";
import useAPI from "../../CustomeHooks/API";
import { CircularProgress } from "@mui/material";
import { API_ROUTE, USER_DATA } from "../../utils/CONSTANTS";
import "./layout.css";

const Layout = () => {
  const [isSmallSize, setIsSmallSize] = useState(window.innerWidth < 1200);
  const getMyProfile = useAPI(API_ROUTE.getMe);
  const navContext = useContext(NavConstant);
  navContext.checkIsLogin();

  useEffect(() => {
    if (navContext.IsLogin) {
      getMyProfile.apiCall();
    }
  }, [navContext.IsLogin]);

  if (getMyProfile.res !== -2 && getMyProfile.res !== -1) {
    authStorage.setRole(getMyProfile.res?.data?.data?.data?.role);
  }

  if (!navContext.IsLogin) {
    return (
      <Fragment>
        {navContext.Error.isShow && <Notification {...navContext.Error} />}
        <Routes>
          <Route path="/*" element={<Login />} />
        </Routes>
      </Fragment>
    );
  }

  if (navContext.IsLogin) USER_DATA.user = getMyProfile.res?.data?.data?.data;
  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setIsSmallSize(true);
    } else {
      setIsSmallSize(false);
    }
  };

  return (
    <Fragment>
      <div className="layout">
        {navContext.Error.isShow && <Notification {...navContext.Error} />}
        <div
          className={
            isSmallSize
              ? navContext.isNavigationOverLap
                ? "overlap"
                : "hide"
              : "show"
          }
        >
          <LeftNavigationBar />
        </div>
        <div className="layout__content">
          <div className="layout__content-main">
            {!getMyProfile.loading ? (
              <AllRoutes />
            ) : (
              <div className="loader-full-size-wrapper">
                <CircularProgress color="success" />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
