import React from "react";

export default function Poster({ heading, isDownload, src }) {
  return (
    <div class="col-lg-3 col-md-4 col-sm-12">
      <div class="poster-box">
        <h3>{heading}</h3>
        <img src={src} class="img-fluid" />
      </div>
      {isDownload && isDownload === true && (
        <a href={src} className="poster-download-text">
          Download
        </a>
      )}
    </div>
  );
}
