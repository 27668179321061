import React from "react";
import PageHeader from "../../components/page-header";
import { CONSTANTS } from "../../utils/CONSTANTS";
import Ifream from "./ifream";

export default function Faq() {
  return (
    <>
      <PageHeader destination={"Support"} heading={"FAQ"} />{" "}
      <Ifream src={CONSTANTS.CONTACT_URL} />
    </>
  );
}
