import { useState } from "react";
import PageHeader from "../../../components/page-header";
import { CONSTANTS } from "../../../utils/CONSTANTS";
import "../business.css";

const AddBusiness = () => {
  const [errCode, setErrCode] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target[0].value.trim() === "") {
      setErrCode(1);
      return;
    }
    if (e.target[1].value.trim() === "") {
      setErrCode(2);
      return;
    }
    if (e.target[2].value.trim() === "") {
      setErrCode(3);
      return;
    }
    setErrCode(0);
    setLoading(true);
  };

  const removeError = () => {
    setErrCode(0);
  };

  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader
        destination="Add Business"
        heading={CONSTANTS.ADD_BUSSINESS_HEADING}
      />
      <div className="container-fluid py-4">
        <h3>{CONSTANTS.ADD_BUSSINESS_LINE}</h3>
        <form
          className="small-form user-form form-mt needs-validation"
          novalidate
          onSubmit={handleSubmit}
        >
          <div className="form-group has-validation col-md-6">
            <input
              placeholder="Business Name"
              name="name"
              className="form-control"
              onChange={removeError}
            />{" "}
            {errCode === 1 && (
              <div className="invalid-feedback feedback">
                Please provide a business name.
              </div>
            )}
          </div>

          <div className="form-group col-md-6">
            <input
              placeholder="Email ID"
              name="email"
              onChange={removeError}
              type="email"
              className="form-control"
            />
            {errCode === 2 && (
              <div className="invalid-feedback feedback">
                Please provide an Email ID.
              </div>
            )}
          </div>
          <div className="form-group col-md-6">
            {" "}
            <input
              placeholder="Password"
              name="password"
              type="password"
              className="form-control"
              onChange={removeError}
            />
            {errCode === 3 && (
              <div className="invalid-feedback feedback">
                Please provide password
              </div>
            )}
          </div>
          <div className="col-md-6 ">
            {!loading ? (
              <button className="btn btn-primary ">Confirm</button>
            ) : (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Loading...</span>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBusiness;
