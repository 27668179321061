import React from "react";
import PageHeader from "../../components/page-header";
import { CONSTANTS } from "../../utils/CONSTANTS";
import Ifream from "./ifream";

export default function Contact() {
  return (
    <>
      <PageHeader destination="Support" heading="Contact" />
      <Ifream src={CONSTANTS.CONTACT_URL} />
    </>
  );
}
