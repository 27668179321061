import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Poster from "../pages/Setup/Poster/index";
import AllPosters from "../pages/Setup/AllPoster/index";
import Home from "../pages/Dashboard/home";
import MobileCouponsLogo from "../pages/MobileCoupons/MobileCouponsLogo/mobileCouponsLogo";
import Expirations from "../pages/MobileCoupons/MobileCouponsExpiration/Expirations";
import Users from "../pages/Setup/Users/users";
import Gamification from "../pages/Setup/Gamification/gamification";
import Labels from "../pages/Setup/Labels/labels";
import Reminders from "../pages/MobileCoupons/Reminders/reminders";
import Contact from "../pages/Support/index.js";
import Pricing from "../pages/Setup/Pricing/pricing";
import MobileCouponPricing from "../pages/Setup/Pricing/mobile_coupon_pricing";
import GamificationInner from "../pages/Setup/Gamification/gamificationInner";
import AddBusiness from "../pages/Business/AddBusiness/addBusiness";
import AllBusiness from "../pages/Business/AllBusiness/allBusiness";
import DesignLabels from "../pages/Setup/Labels/DesignLabels";
import Login from "../pages/auth/Login";
import Faq from "../pages/Support/faq";
import AllMerchants from "../pages/Business/AllMerchants/AllMerchants";
import CashOrCard from "../pages/Setup/cashorcard";
import TermsOfUsage from "../pages/Support/terms-of-usage";
const AllRoutes = () => {
  return (
    <Routes>
      <Route exact path="/add_business" element={<AddBusiness />} />
      <Route exact path="/all_business" element={<AllBusiness />} />
      <Route exact path="/all_merchants" element={<AllMerchants />} />
      <Route exact path="/setup_posters" element={<Poster />} />
      <Route exact path="/posters/:category" element={<AllPosters />} />
      <Route exact path="/mobilecoupons_logo" element={<MobileCouponsLogo />} />
      <Route
        exact
        path="/mobilecoupons_expirations"
        element={<Expirations />}
      />
      <Route exact path="/mobilecoupons_reminders" element={<Reminders />} />
      <Route exact path="/setup_users" element={<Users />} />
      <Route exact path="/setup_gamifications" element={<Gamification />} />
      <Route exact path="/setup_cashorCard" element={<CashOrCard />} />
      <Route
        exact
        path="/gamifications-inner"
        element={<GamificationInner />}
      />
      <Route exact path="/setup_labels" element={<Labels />} />
      <Route exact path="/design_labels" element={<DesignLabels />} />
      <Route exact path="/support_Contact" element={<Contact />} />
      {/* <Route exact path="/setup_pricing" element={<Pricing />} /> */}
      <Route exact path="/setup_pricing" element={<MobileCouponPricing />} />
      <Route exact path="/support_faq" element={<Faq />} />
      <Route exact path="/terms" element={<TermsOfUsage />} />
      <Route exact path="login" element={<Home />} />
      <Route path="/*" element={<Home />} />
    </Routes>
  );
};
//gamifications-inner

export default AllRoutes;
