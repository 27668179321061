import { TextField } from "@mui/material";
import PageHeader from "../../../components/page-header";
import { NavConstant } from "../../../store/context";
import Warning from "../../../components/Ui/warning";
import useAPI from "../../../CustomeHooks/API";

import {
    API_ROUTE,
    COLOR,
    CONSTANTS,
    NOTIFICATION_MESSAGE,
    USER_DATA,
} from "../../../utils/CONSTANTS";
import "./mobile_coupon_pricing.css";
import { useState, useContext, useEffect } from "react";

const MobileCouponPricing = () => {

    const [clientPercent, setClientPercent] = useState(0);
    const [clientAmount, setClientAmount] = useState(0);
    const [doubleDiscountPercent, setDoubleDiscountPercent] = useState(0);
    const [doubleDiscountAmount, setDoubleDiscountAmount] = useState(0);
    const [isWarningShow, setIsWarningShow] = useState(false);
    const navContext = useContext(NavConstant);
    const getMerchant = useAPI(API_ROUTE.getMerchantByEmail);
    const [totalDiscountPercent, setTotalDiscountPercent] = useState(0);
    const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
    const [isDiscountUpdate, setIsDiscountUpdate] = useState(false);


    useEffect(() => {

        if (navContext.merchantEmail) {
            getMerchant.apiCall({ email: navContext.merchantEmail });
        } else {

            if (USER_DATA?.user && (USER_DATA?.user?.role !== "admin" && USER_DATA?.user?.role !== "super-admin")) {
                // setClientAmount(USER_DATA?.user?.discount_amount || 0);
                // setClientPercent(USER_DATA?.user?.discount_percentage || 0);

                // setDoubleDiscountAmount(USER_DATA?.user?.double_discount_amount || 0);
                // setDoubleDiscountPercent(USER_DATA?.user?.double_discount_percentage || 0);

                let discount_amount = USER_DATA?.user?.discount_amount / 100 || 0;
                let discount_percentage = USER_DATA?.user?.discount_percentage || 0;
                setClientAmount(discount_amount);
                setClientPercent(discount_percentage);

                let double_discount_amount = USER_DATA?.user?.double_discount_amount / 100 || 0;
                let double_discount_percentage = USER_DATA?.user?.double_discount_percentage || 0;
                setDoubleDiscountAmount(double_discount_amount);
                setDoubleDiscountPercent(double_discount_percentage);

                let apply_discount_amount = Number((USER_DATA?.user?.apply_discount_amount / 100 || (Math.max(discount_amount, double_discount_amount))).toFixed(2));
                let apply_discount_percent = USER_DATA?.user?.apply_discount_percent || (Math.max(discount_percentage, double_discount_percentage));
                setTotalDiscountAmount(apply_discount_amount);
                setTotalDiscountPercent(apply_discount_percent);
            }

        }
    }, [navContext.merchantEmail, USER_DATA.user]);

    // console.log("getMerchant: ", getMerchant)
    useEffect(() => {

        if (USER_DATA?.user && (USER_DATA?.user?.role === "admin" || USER_DATA?.user?.role === "super-admin")) {

            if (getMerchant.loading === false) {

                const merchant = getMerchant?.res?.data?.data?.data;

                let discount_amount = merchant?.discount_amount / 100 || 0;
                let discount_percentage = merchant?.discount_percentage || 0;
                setClientAmount(discount_amount);
                setClientPercent(discount_percentage);

                let double_discount_amount = merchant?.double_discount_amount / 100 || 0;
                let double_discount_percentage = merchant?.double_discount_percentage || 0;
                setDoubleDiscountAmount(double_discount_amount);
                setDoubleDiscountPercent(double_discount_percentage);

                let apply_discount_amount = Number((merchant?.apply_discount_amount / 100 || (Math.max(discount_amount, double_discount_amount))).toFixed(2));
                let apply_discount_percent = merchant?.apply_discount_percent || (Math.max(discount_percentage, double_discount_percentage));
                setTotalDiscountAmount(apply_discount_amount);
                setTotalDiscountPercent(apply_discount_percent);
            }
        }
    }, [getMerchant.loading, USER_DATA.user]);

    useEffect(() => {
        isDiscountUpdate && setTotalDiscountPercent(Math.max(clientPercent, doubleDiscountPercent));
    }, [clientPercent, doubleDiscountPercent])

    useEffect(() => {
        isDiscountUpdate && setTotalDiscountAmount(Math.max(clientAmount, doubleDiscountAmount));
    }, [clientAmount, doubleDiscountAmount])

    const handleChangePercentage = (e) => {
        setIsDiscountUpdate(true)
        setClientPercent(e.target.value);
    };
    const handleChangeAmount = (e) => {
        setIsDiscountUpdate(true)
        setClientAmount(e.target.value);
    };

    const handleChangeDoubleDiscountPercentage = (e) => {
        setIsDiscountUpdate(true)
        setDoubleDiscountPercent(e.target.value);
    };
    const handleChangeDoubleDiscountAmount = (e) => {
        setIsDiscountUpdate(true)
        setDoubleDiscountAmount(e.target.value);
    };

    const handleChangeTotalDiscountPercentage = (e) => {
        setTotalDiscountPercent(e.target.value);
    };
    const handleChangeTotalDiscountAmount = (e) => {
        setTotalDiscountAmount(e.target.value);
    };

    const updateDiscount = useAPI(
        API_ROUTE.setDiscountOnInventory,
        NOTIFICATION_MESSAGE.UPDATE_SUCCESS
    );

    let arr = [];

    if (getMerchant.res !== -2 && getMerchant.res !== -1) {
        arr = getMerchant.res;
    }

    const handleChangeDiscount = (e) => {
        e.preventDefault();
        setIsWarningShow(false);
        updateDiscount.apiCall({
            discount_amount: parseInt(
                (Number(clientAmount) * 100) || arr?.data?.data?.data?.discount_amount
            ),
            discount_percentage:
                clientPercent || arr?.data?.data?.data?.discount_percentage,
            double_discount_amount: parseInt(
                (doubleDiscountAmount * 100) || arr?.data?.data?.data?.double_discount_amount
            ),
            double_discount_percentage:
                doubleDiscountPercent || arr?.data?.data?.data?.double_discount_percentage,
            apply_discount_amount: parseFloat(
                (totalDiscountAmount)
            ),
            apply_discount_percent:
                totalDiscountPercent
        });
    };

    // console.log("totalDiscountAmount: ", totalDiscountAmount)
    // console.log("clientPercent: ", clientPercent === "-")

    return (
        <div className="main-content position-relative h-100 border-radius-lg">
            <PageHeader
                destination="Adjust Discounts"
                heading={CONSTANTS.PRICING_TITLE}
            />
            <Warning
                isShow={isWarningShow}
                onClose={() => setIsWarningShow(false)}
                message="This Action will change your all product pricing in your inventory. Are sure want to perform this action?"
                onPostiveChoice={handleChangeDiscount}
                onNegativeChoice={() => setIsWarningShow(false)}
                onPostiveText="Agree"
                onNegativeText="Deny"
            />

            <div className="container-fluid py-4">
                <div className="justify-content-center row">
                    <div className="col-xl-12 col-sm-12 mb-xl-0 mb-3">
                        <div className="text-center">
                            <h2 className="text-blue">Adjust Discounts, Offset Credit Card Fees</h2>
                            <div className="hello_text">
                                <p className="lable">Enter Values to Increase pricing and Offset Credit Card Fees & Discounts
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-10 col-sm-12">
                        <div className="main-div text-black">

                            <div className="offer-card-fees justify-content-around">
                                <div className="detail-block">
                                    <h6 className="text-black"> Offer Cash Discount - Offset Card Fees </h6>
                                    <div>Customers who Pay with Cash today <b>SAVE TODAY:</b></div>
                                    <p className="small-text">Recommended : 3% + .10¢</p>
                                </div>
                                <div className="input-block">
                                    <div>
                                        <label>Card Fee %:</label>
                                        <input
                                            type={"number"}
                                            className="form-control form-control-sm input-width"
                                            placeholder=""
                                            aria-controls="mytable"
                                            value={clientPercent}
                                            onChange={(e) => {
                                                if (e.target.value > 10 || e.target.value < -10) {
                                                    navContext.setIsErrorNotification({
                                                        type: false,
                                                        message:
                                                            "The discount percentage should be between -10.00% to 10.00%",
                                                    });
                                                } else {
                                                    handleChangePercentage(e);
                                                }
                                            }}
                                        />
                                        <span>{Number(clientPercent).toFixed(2)}%</span>
                                    </div>
                                    <div class="mx-4 "><p class=" plus">+</p></div>
                                    <div >
                                        <label>Card Trans Fee $:</label>
                                        <input
                                            type={"number"}
                                            className="form-control form-control-sm input-width"
                                            placeholder=""
                                            aria-controls="mytable"
                                            value={clientAmount}
                                            onChange={(e) => {
                                                if (e.target.value > 1 || e.target.value < -1) {
                                                    navContext.setIsErrorNotification({
                                                        type: false,
                                                        message:
                                                            "The discount Amount should be between -$1.00 to $1.00",
                                                    });
                                                } else {
                                                    handleChangeAmount(e);
                                                }
                                            }}
                                        />
                                        <span>
                                            {(clientAmount) < 0 ? "-" : ""}
                                            {Math.abs(Number(clientAmount)) >= 1 ? "$" : ""}
                                            {Math.abs(Number(clientAmount)).toFixed(2)}
                                            {Math.abs(Number(clientAmount)) >= 1 ? "" : "¢"}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="offer-card-fees justify-content-around pt-5">

                                <div className="detail-block">
                                    <h6 className="text-black">Offer Discounts For Next Purchase</h6>
                                    <div>Customers who Pay with Cash will receive a Mobile Coupon to <b>SAVE NEXT VISIT:</b></div>
                                    <p className="small-text">Recommended Double Cash Discount % (Card Fee %)</p>
                                </div>
                                <div className="input-block">
                                    <div>
                                        <label>Discount %:</label>
                                        <input
                                            type={"number"}
                                            className="form-control form-control-sm input-width"
                                            placeholder=""
                                            aria-controls="mytable"
                                            value={doubleDiscountPercent}
                                            onChange={(e) => {
                                                if (e.target.value > 10 || e.target.value < -10) {
                                                    navContext.setIsErrorNotification({
                                                        type: false,
                                                        message:
                                                            "The discount percentage should be between -10.00% to 10.00%",
                                                    });
                                                } else {
                                                    handleChangeDoubleDiscountPercentage(e);
                                                }
                                            }}
                                        />
                                        <span>{Number(doubleDiscountPercent).toFixed(2)}%</span>
                                    </div>
                                    <div class="mx-4"><p class="plus">+</p></div>
                                    <div >
                                        <label>Discount Trans $:</label>
                                        <input
                                            type={"number"}
                                            className="form-control form-control-sm input-width"
                                            placeholder=""
                                            aria-controls="mytable"
                                            value={doubleDiscountAmount}
                                            onChange={(e) => {
                                                if (e.target.value > 1 || e.target.value < -1) {
                                                    navContext.setIsErrorNotification({
                                                        type: false,
                                                        message:
                                                            "The discount Amount should be between -$1.00 to $1.00",
                                                    });
                                                } else {
                                                    handleChangeDoubleDiscountAmount(e);
                                                }
                                            }}
                                        />
                                        <span>
                                            {(doubleDiscountAmount) < 0 ? "-" : ""}
                                            {Math.abs(Number(doubleDiscountAmount)) >= 1 ? "$" : ""}
                                            {Math.abs(Number(doubleDiscountAmount)).toFixed(2)}
                                            {Math.abs(Number(doubleDiscountAmount)) >= 1 ? "" : "¢"}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="offer-card-fees justify-content-around">
                                <div className="detail-block">
                                    <h6 className="text-black">Total Price Increase / Decrease Offset</h6>
                                    <div>All pricing will be changed by this amount</div>
                                    <p className="small-text">Total Pricing Offset = The greater of all discounts entered above unless manually adjusted</p>
                                </div>
                                <div className="input-block">
                                    <div>
                                        <div class="d-flex">
                                            <div>
                                                <label className="text-green">Total Change %:</label>
                                                <input
                                                    type={"number"}
                                                    className="form-control form-control-sm input-width"
                                                    placeholder=""
                                                    aria-controls="mytable"
                                                    value={totalDiscountPercent}
                                                    onChange={(e) => {
                                                        if (e.target.value > 10 || e.target.value < -10) {
                                                            navContext.setIsErrorNotification({
                                                                type: false,
                                                                message: "The discount percentage should be between -10.00% to 10.00%",
                                                            });
                                                        } else {
                                                            handleChangeTotalDiscountPercentage(e);
                                                        }
                                                    }}
                                                />
                                                <span>{Number(totalDiscountPercent).toFixed(2)}%</span>
                                            </div>
                                            <div class="mx-4 "><p class="plus">+</p></div>
                                            <div >
                                                <label className="text-green">Total Change $:</label>
                                                <input
                                                    type={"number"}
                                                    className="form-control form-control-sm input-width"
                                                    placeholder=""
                                                    aria-controls="mytable"
                                                    value={totalDiscountAmount}
                                                    onChange={(e) => {
                                                        if (e.target.value > 1 || e.target.value < -1) {
                                                            navContext.setIsErrorNotification({
                                                                type: false,
                                                                message: "The discount Amount should be between -$1.00 to $1.00",
                                                            });
                                                        } else {
                                                            handleChangeTotalDiscountAmount(e);
                                                        }
                                                    }}
                                                />
                                                <span>
                                                    {(totalDiscountAmount) < 0 ? "-" : ""}
                                                    {Math.abs(Number(totalDiscountAmount)) >= 1 ? "$" : ""}
                                                    {Math.abs(Number(totalDiscountAmount)).toFixed(2)}
                                                    {Math.abs(Number(totalDiscountAmount)) >= 1 ? "" : "¢"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="pt-2 text-blue text-bolder discount-instruction">*Your pricing will be increased by this amount. Enter a (- Negative) to reverse or decrease pricing</div>
                                    </div>

                                </div>
                            </div>
                            <div className="pt-4">
                                <div className="text-green"><b>*Pricing Offset:</b></div>
                                <div className="text-green">YOU MUST ALSO SYNC YOUR INVENTORY WITHIN THE CLOVER POS BY SELECTING THE SYNC ICON WITHIN THE CLOVER INVENTORY APP</div>
                            </div>
                            <div className="d-flex justify-content-center justify-content-sm-end pt-4">

                                <button
                                    type="submit"
                                    className="btn pricing-confirm-btn"
                                    style={{
                                        backgroundColor: updateDiscount.loading
                                            ? COLOR.THEME.secondory
                                            : COLOR.THEME.primary,
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsWarningShow(true);
                                    }}
                                    disabled={updateDiscount.loading}
                                >
                                    {updateDiscount.loading
                                        ? "Updating inventory..."
                                        : (<span>Sync Inventory <i className="fa fa-arrow-right"></i></span>)}
                                </button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MobileCouponPricing;