import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
};

const Warning = ({ isShow, onClose, message, onPostiveChoice, onNegativeChoice, onPostiveText, onNegativeText, isSingleButton }) => {
    return (
        <Modal
            open={isShow}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {message}
                </Typography>
                <Box>
                    <Button className="me-3 mt-3" onClick={onPostiveChoice} variant="outlined">
                        {onPostiveText}
                    </Button>
                    {!isSingleButton && <Button className="mt-3" onClick={onNegativeChoice} variant="outlined">
                        {onNegativeText}
                    </Button>}
                </Box>
            </Box>
        </Modal>
    );
};

Warning.defaultProps = {
    isShow: false,
    onClose: () => { },
    message: "",
    onPostiveChoice: () => { },
    onNegativeChoice: () => { },
    onPostiveText: "Yes",
    onNegativeText: "No"
}

export default Warning;
