import { ACCESS_TOKEN_KEY } from "./urls";
import { getCookie, setCookie, eraseCookie } from "./cookies";

class AuthStorage {
  get authToken() {
    return getCookie(ACCESS_TOKEN_KEY);
  }

  get mid() {
    return localStorage.getItem("mid");
  }

  get mToken() {
    return localStorage.getItem("mToken");
  }

  get role() {
    return localStorage.getItem("role");
  }

  setAuthDetails = async (accessToken) => {
    setCookie(ACCESS_TOKEN_KEY, accessToken, 1);
    localStorage.setItem("token", accessToken);
  };

  deleteAuthDetails = () => {
    eraseCookie(ACCESS_TOKEN_KEY);
    localStorage.removeItem("mid");
    localStorage.removeItem("role");
  };

  setMID = async (mid) => {
    localStorage.setItem("mid", mid);
  };

  setMToken = async (token) => {
    localStorage.setItem("mToken", token);
  };

  setRole = async (role) => {
    localStorage.setItem("role", role);
  };
}

export const authStorage = new AuthStorage();
