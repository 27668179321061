import { useEffect, useState } from "react";
import UserRed from "../../../assets/img/user1.png";
import UserBlue from "../../../assets/img/user2.png";
import UserGreen from "../../../assets/img/user3.png";
import "./card.css";

const Card = ({ heading, value }) => {
  const [img, setImg] = useState("");

  useEffect(() => {
    if (
      heading.toLowerCase().indexOf("customer") !== -1 &&
      heading.toLowerCase().indexOf("sales") === -1
    ) {
      if (heading.toLowerCase().indexOf("total") !== -1) {
        setImg(UserRed);
      } else if (heading.toLowerCase().indexOf("new") !== -1) {
        setImg(UserBlue);
      } else {
        setImg(UserGreen);
      }
    }
  }, [heading]);

  return (
    // <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
    //   <div className="card dashboardcards">
    //     <h3>{heading}</h3>
    //     <span className="number"> {value}</span>
    //     {img !== "" && <img src={img} alt="" />}
    //   </div>
    // </div>
    <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
      <div
        className={`card dashboardcards ${
          img === "" ? "dashboard_inner_cards" : ""
        }`}
      >
        <div className="card-body p-3">
          <div className="row">
            <div className="col-12">
              <div className="numbers">
                <p className="text-sm mb-0 text-capitalize font-weight-bold">
                  {heading}
                </p>
                <h5 className="font-weight-bolder mb-0">{value}</h5>
              </div>
            </div>
            <div className="col-12 text-end">
              <div className="icon icon-shape shadow text-center border-radius-md">
                {img !== "" && <img src={img} alt="" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
