import PageHeader from "../../../components/page-header";
import { CONSTANTS } from "../../../utils/CONSTANTS";

const AllBusiness = () => {
  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader
        destination="Add Business"
        heading={CONSTANTS.ALL_BUSSINESS_HEADING}
      />
      <div className="container-fluid py-4">
        <h3>{CONSTANTS.ALL_BUSSINESS_HEADING}</h3>
        <div className="col-sm-12 col-md-6 mt-4">
          <div id="mytable_filter" className="dataTables_filter">
            <label>
              Search:
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder=""
                aria-controls="mytable"
              />
            </label>
          </div>
        </div>
        <table
          id="mytable"
          className="table table-striped table-bordered"
          cellspacing="0"
          width="100%"
        >
          <thead>
            <tr className="head-row">
              <th className="th-sm">No.</th>
              <th className="th-sm">Business Name</th>
              <th className="th-sm">Email ID</th>
              <th className="th-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            <td colSpan={4} align="center">No business found</td>
            {/* <tr>
              <td>1</td>
              <td>System Architect</td>
              <td>Edinburgh@gmail.com</td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle py-1 px-2 fs-6 mb-0"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>Delete</li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>System Architect</td>
              <td>Edinburgh@gmail.com</td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle py-1 px-2 fs-6 mb-0"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>Delete</li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>System Architect</td>
              <td>Edinburgh@gmail.com</td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle py-1 px-2 fs-6 mb-0"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>Delete</li>
                  </ul>
                </div>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllBusiness;
