import { useEffect, useState } from "react";
import PageHeader from "../../../components/page-header";
import Table from "../../../components/Ui/table";
import useAPI from "../../../CustomeHooks/API";
import { API_ROUTE, CONSTANTS, USER_DATA } from "../../../utils/CONSTANTS";
import moment from "moment";

const AllMerchants = () => {
  const headers = [
    "MID",
    "Name",
    "Email",
    "Mobile Number",
    "Address",
    "City",
    "State",
    "Zip",
    "Hear About Us",
    "Agent Or Msp Name",
    "Discount Percentage",
    "Discount Amount",
    "Opt In Signage",

    "Opt In Double Discount",
    "Opt In Mobile Coupon",

    "Legal Business Name",
    "Signed By",
    "Coupon Expires In",
    "Role",
    "Start Date",
  ];

  let merchantsArr = [];

  const merchantAPI = useAPI(API_ROUTE.getAllMerchants);
  const merchantByBussAPI = useAPI(API_ROUTE.getMerchantsByBusiness);

  const [searchWord, setSearchWord] = useState("");

  useEffect(() => {
    if (USER_DATA?.user?.role) {

      if (USER_DATA?.user?.role === "super-admin") {
        merchantAPI.apiCall();
      } else if (USER_DATA?.user?.role === "admin") {
        merchantByBussAPI.apiCall({ businessId: USER_DATA?.user?.businessId })
      }
    }

  }, [USER_DATA?.user?.role]);


  if ((merchantAPI.res !== -2 && merchantAPI.res !== -1) || (merchantByBussAPI.res !== -2 && merchantByBussAPI.res !== -1)) {
    let resData = USER_DATA?.user?.role === "super-admin" ? merchantAPI.res.data.data.data : merchantByBussAPI.res.data.data.data;
    resData.filter((row) => {
      if (row.email.includes(searchWord)) {
        // console.log({ row });
        return merchantsArr.push({
          mid: row.mid,
          name: row.firstname + " " + row.lastname,
          email: row.email,
          mobile_number: row.mobile_number,
          address: row.address,
          city: row.city,
          state: row.state,
          zip: row.zip,
          hear_about_us: row.hear_about_us,
          agent_or_msp_name: row.agent_or_msp_name,
          discount_percentage: row.discount_percentage,
          discount_amount: row.discount_amount,
          opt_in_signage: row.opt_in_signage,
          opt_in_double_discount: row.opt_in_double_discount ? "Yes" : "No",
          opt_in_mobile_coupon: row.opt_in_mobile_coupon ? "Yes" : "No",
          legal_business_name: row.legal_business_name,
          signed_by: row.signed_by,
          coupon_expires_in: row.coupon_expires_in,
          role: row.role,
          created_at: (row.createdAt ? moment(row.createdAt).format("MM/DD/YYYY HH:mm A") : "-"),
        });
      } else return;
    });
  }

  const handleSearch = (e) => {
    setSearchWord(e.target.value);
  };


  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader
        destination="All Merchants"
        heading={CONSTANTS.ALL_BUSSINESS_HEADING}
      />
      <div className="container-fluid py-4 overflow-scroll">
        <h3>{CONSTANTS.ALL_MERCHANTS_HEADING}</h3>
        <div className="col-sm-12 col-md-6 mt-4">
          <div id="mytable_filter" className="dataTables_filter">
            <label>
              Search:
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder=""
                aria-controls="mytable"
                onChange={handleSearch}
              />
            </label>
          </div>
        </div>
        {merchantsArr.length > 0 && (
          <div className="col-12 overflow-scroll">
            <Table headers={headers} data={merchantsArr} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AllMerchants;
