import React from "react";

export default function Table({ headers, data }) {
  console.log({ data });
  return (
    <table
      id="mytable"
      className="table table-striped table-bordered overflow-scroll w-75"
      cellSpacing="0"
      width="100%"
    >
      <thead>
        <tr className="head-row">
          {headers.map((heading, index) => (
            <th key={"tableHeading_" + index} className="th-sm">
              {heading}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={"tableBody_" + index}>
            {Object.values(item).map((itemData, index) => (
              <td
                key={index + "tableColumn_" + index}
                style={{ textAlign: "center" }}
              >
                {itemData}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
