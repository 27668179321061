import React, { useEffect, useState } from "react";
import moment from "moment";
import { GetAllTransaction } from "../../../api/allServices";
import ActionBar from "../../../components/ActionBar";
import StartDateFromDate from "../../../components/StartDateFromDate";
import NoItemFound from "../../../components/Ui/no-item-found";
import Table from "../../../components/Ui/table";
import getStartEndDate from "../../../utils/timeDurationGenerator";
import CustomDate from "../../../components/Home/customDate/customDate";
import { CSVLink } from "react-csv";
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const Transaction = ({ setIsLoading }) => {
  const [searchValue, setSearchValue] = useState("");
  const [timeInterVal, setTimeInterval] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).local().format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    moment(tomorrow).local().format("YYYY-MM-DD")
  );

  const headings = [
    "No",
    "Created",
    "Time",
    "Trans Id",
    "Customer",
    "Discount Type",
    "Transaction Total",
    "Discount Total",
    "Coupon Id",
    "Coupon Code",
  ];

  var option = [
    { value: "DAILY" },
    { value: "WEEKLY" },
    { value: "MONTHLY" },
    { value: "CUSTOM" },
  ];

  const SelectOption = (e) => {
    setTimeInterval(e.target.value);
    console.log("val", e.target.value);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const timeDuration = getStartEndDate(timeInterVal, startDate, endDate);
        console.log(...timeDuration);
        const res = await GetAllTransaction(timeDuration[0], timeDuration[1]);
        if (res?.data?.data?.data) {
          console.log(res?.data?.data?.data);
          setTransactions(
            res?.data?.data?.data.reverse().map((item, index) => {
              return {
                no: index + 1,
                createdAt: moment(item.createdAt).local().format("YYYY-MM-DD"),
                createdTime: moment(item.createdAt)
                  .local()
                  .format("hh:mm:ss a"),
                id: item._id,
                customer: item.customer_mobile,
                discount_type: item.discount_type,
                transaction_total: "$" + item.transaction_total.toFixed(2),
                discount_total:
                  item.discount_total != ""
                    ? "$" + item.discount_total.toFixed(2)
                    : "$0",

                coupon_id: item?.coupon_id?._id,
                couponCode: item?.coupon_id?.code,
              };
            })
          );
        }
        setIsLoading(false);
        console.log(res);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    })();
  }, [timeInterVal, startDate, endDate]);

  const filteredTransaction = transactions.filter(
    (transaction) =>
      searchValue === "" ||
      transaction.id.toLowerCase().includes(searchValue.toLowerCase()) ||
      (transaction.customer + "")
        .toLowerCase()
        .includes(searchValue.toLowerCase())
  );
  const CSVData = [];
  CSVData[0] = headings;
  filteredTransaction.map((item, index) => {
    console.log("item", item);
    CSVData[index + 1] = Object.values(item);
  });
  return (
    <>
      <div className="mt-3">
        <ActionBar
          option={option}
          onChange={SelectOption}
          value={timeInterVal}
          title={"Transactions"}
        />
      </div>
      <div className="mt-3">
        {timeInterVal == "CUSTOM" && (
          <CustomDate
            updateSDate={(e) => setStartDate(e.target.value)}
            updateLDate={(e) => setEndDate(e.target.value)}
          />
        )}
      </div>

      <div class="table-responsive dataTables mt-5">
        <div class="row">
          {/* <div class="col-sm-12 col-md-3">
            <div class="dataTables_length" id="mytable_length">
              <label>
                Show
                <select
                  name="mytable_length"
                  aria-controls="mytable"
                  onChange={(e) => setDataLimit(e.target.value)}
                  class="custom-select custom-select-sm form-control form-control-sm"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
          </div> */}
          <div class="col-sm-12 col-md-6">
            <div id="mytable_filter" class="dataTables_filter">
              <label>
                Search:
                <input
                  type="search"
                  class="form-control form-control-sm"
                  placeholder=""
                  aria-controls="mytable"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </label>
            </div>
          </div>

          {filteredTransaction.length !== 0 && (
            <div class="col-sm-12 col-md-6 d-flex">
              <CSVLink
                data={CSVData}
                filename="Transaction History"
                className="ExportBtn"
              >
                Export CSV
              </CSVLink>
            </div>
          )}
        </div>
        {filteredTransaction.length === 0 && (
          <NoItemFound
            message="No Transaction Found"
            style={{ marginTop: "10px" }}
          />
        )}
        {filteredTransaction.length > 0 && (
          <Table headers={headings} data={filteredTransaction} />
        )}
      </div>
      <div className="col-xl-12 col-sm-12 mb-xl-0 mb-4">
        <div className="hello_text">
          <p className="lable">
            Transaction detail visible to managers/owners only
          </p>
          {/* <p className="lable">
            *Note: Mobile Coupons bill at 10¢ per message sent (limit 2 text
            reminders per customer per month)
          </p> */}
        </div>
      </div>
    </>
  );
};

export default Transaction;
