import { LinearProgress } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { getGamificationData, updateGamificationData } from "../../../api/allServices";
import PageHeader from "../../../components/page-header";
import { COLOR, CONSTANTS, USER_DATA } from "../../../utils/CONSTANTS";
import { NavConstant } from "../../../store/context";
import "./gamification.css";

const Gamification = () => {
  const [toggleBtn, setToggleBtn] = useState(true);
  const [selectedPos, setSelectedPos] = useState(0);
  const [selectedPhone, setSelectedPhone] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [refrash, setRefreash] = useState(false);
  const navContext = useContext(NavConstant);
  console.log("POS", selectedPos);
  console.log("Phone", selectedPhone);

  const handleToggle = (val) => {
    setToggleBtn(val);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const mid = (USER_DATA?.user?.role === "admin" || USER_DATA?.user?.role === "super-admin") ? navContext?.merchantId : USER_DATA.user?.mid;
      if (mid) {

        const res = await getGamificationData(mid);

        if (res?.data?.status == "success") {


          setSelectedPhone(res?.data?.data?.data?.phoneGami + "" ? res?.data?.data?.data?.phoneGami : 1)
          setSelectedPos(res?.data?.data?.data?.posGami + "" ? res?.data?.data?.data?.posGami : 1)
          setToggleBtn(res?.data?.data?.data?.status ? res?.data?.data?.data?.status : false)
        }
        setIsLoading(false);
      }

    })();
  }, [refrash, navContext.merchantEmail, USER_DATA.user])

  const updateData = (phoneGami, posGami, status) => {
    (async () => {
      setIsLoading(true);
      const payload = {
        "phoneGami": phoneGami,
        "posGami": posGami,
        "status": status,
        "mid": USER_DATA.user?.mid
      }
      console.log(payload);

      const res = await updateGamificationData(payload);
      console.log(res);
      if (res?.data?.status == "success") {
        setRefreash(previous => !previous);
      }
      setIsLoading(false);
    })();
  }

  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader
        destination={CONSTANTS.GAMIFICATION_TITLE}
        heading={CONSTANTS.GAMIFICATION_TITLE}
      />
      {isLoading && (
        <LinearProgress color="inherit" sx={{ margin: "0px 10px" }} />
      )}
      <div className="row align-items-center justify-content-center container-fluid py-4 gamification">
        <div className="col-lg-8 col-md-7 col-sm-12">
          <h3>{CONSTANTS.GAMIFICATION_HEADING}</h3>
          <div className="hello_text">
            <p className="lable">{CONSTANTS.GAMIFICATION_SUB_LINE}</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-5 col-sm-12">
          <div className="up-in-toggle">
            <input
              type="radio"
              id="switch_left"
              name="switch_2"
              value="no"
              checked={!toggleBtn}
              onClick={() => { updateData(selectedPos, selectedPos, false); handleToggle(false) }}
            />
            <label for="switch_left">Gamify Off</label>
            <input
              type="radio"
              id="switch_right"
              name="switch_2"
              value="yes"
              checked={toggleBtn}
              onClick={() => { updateData(selectedPos, selectedPos, true); handleToggle(true) }}
            />
            <label for="switch_right" style={{ background: toggleBtn ? COLOR.THEME.secondory : "" }}>Gamify On</label>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3" >
          <h3 className="same-title text-center" style={{ marginTop: "30px" }}>
            {CONSTANTS.CHECKOUT_EXPERIENCE_LABEL}
          </h3>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3" >
          <h3 className="same-title" style={{ marginTop: "10px" }}>
            {CONSTANTS.CHECKOUT_YOUR_GAMIFICATION}
          </h3>
        </div>
        <div className="row justify-content-center" style={{ marginTop: "30px" }}>
          {CONSTANTS.POS_GAMIFICATIONS.map((gamification, index) => {
            return (
              <div
                className={`col-lg-3 col-md-6 col-sm-12 mt-3 ${gamification.subscription == "Comming Soon" ? "gamification-wrapper-coomin-soon" : "gamification-wrapper"}   ${selectedPos == index ? "gamification-active" : ""}`}
                key={gamification.id}
                onClick={() => { if (gamification.subscription != "Comming Soon") { updateData(selectedPhone, index, toggleBtn); setSelectedPos(index) } }}
              >
                <div className="game-box">
                  <h3 className="mb-3">
                    POS Gamifications
                    <span style={{ color: gamification.typeColor }}>
                      {gamification.type}
                    </span>
                  </h3>
                  <img
                    src={gamification.imageUrl}
                    className="img-fluid mb-3"
                    alt="POS_Gamification"
                  />
                  <p className="caption" style={{ color: gamification.subscription == "Comming Soon" ? COLOR.THEME.primary : COLOR.THEME.secondory }}>{gamification.subscription}</p>
                </div>
              </div>
            );
          })}
          <div style={{ marginTop: "30px" }}></div>
          {CONSTANTS.MOBILE_GAMIFICATIONS.map((gamification, index) => {
            return (
              <div
                className={`col-lg-3 col-md-6 col-sm-12 mt-3 ${gamification.subscription == "Comming Soon" ? "gamification-wrapper-coomin-soon" : "gamification-wrapper"} ${selectedPhone == index ? "gamification-active" : ""}`}
                key={gamification.id}
                onClick={() => { if (gamification.subscription != "Comming Soon") { updateData(index, selectedPos, toggleBtn); setSelectedPhone(index) } }}
              >
                <div className="game-box">
                  <h3 className="mb-3">
                    Mobile Gamifications
                    <span style={{ color: gamification.typeColor }}>
                      {gamification.type}
                    </span>
                  </h3>
                  <img
                    src={gamification.imageUrl}
                    className="img-fluid mb-3"
                    alt="Mobile_Gamification"
                  />
                  <p className="caption" style={{ color: gamification.subscription == "Comming Soon" ? COLOR.THEME.primary : COLOR.THEME.secondory }}>{gamification.subscription}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Gamification;
