import { Check } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { getReminder, SendCoupon, updateLimit } from "../../../api/allServices";
import ActionBar from "../../../components/ActionBar";
import PageHeader from "../../../components/page-header";
import Table from "../../../components/Ui/table";
import useAPI from "../../../CustomeHooks/API";
import {
  API_ROUTE,
  CONSTANTS,
  NOTIFICATION_MESSAGE,
  USER_DATA,
} from "../../../utils/CONSTANTS";
import { NavConstant } from "../../../store/context";
import { Button, LinearProgress } from "@mui/material";
import moment from "moment";
import NoItemFound from "../../../components/Ui/no-item-found";
import Warning from "../../../components/Ui/warning";

let currentId = -1;
const Reminders = () => {
  const [selectedValue, setSelectedValue] = useState();
  const [reminderTraker, setReminderTraker] = useState({});
  const [searchWord, setSearchWord] = useState("");
  const [Loading, setLoading] = useState("");
  const [Limit, setLimit] = useState({});
  const [opt_in_out, setOpt_in_out] = useState({});
  const [update, setUpdate] = useState(false);
  const [isWarningShow, setIsWarningShow] = useState(false);

  const Context = useContext(NavConstant);

  var option = [
    { value: "time interval" },
    { value: "Monthly" },
    { value: "Daily" },
    { value: "Weekly" },
  ];
  const SelectOption = (e) => {};
  const SelectStartDate = (e) => {
    console.log("SelectStartDate", e.target.value);
  };
  const SelectFromDate = (e) => {};
  const headers = [
    " No.",
    "MID",
    "Code",
    "Mobile",
    "Discount Price",
    "Expiry Date",
    "Text Opt-In",
    "Manage reminder",
    "remind",
  ];

  const unusedCoupon = useAPI(API_ROUTE.getUnusedCoupons);
  const sendReminderAPI = useAPI(
    API_ROUTE.sendReminder,
    NOTIFICATION_MESSAGE.MSG_SEND
  );

  let couponsArr = [];

  const updateUsageLimit = () => {
    (async () => {
      setLoading(true);
      try {
        const payload = {
          traffic_booster_count: Limit?.traffic_booster_count + 1,
          reminder_count: Limit?.reminder_count,
          mid: USER_DATA.user?.mid,
        };
        const res = await updateLimit(payload);
        setLimit(res?.data?.data?.data);
      } catch (error) {}
      setLoading(false);
    })();
  };

  useEffect(() => {
    unusedCoupon.apiCall();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await getReminder(USER_DATA.user?.mid);
        setLimit(res?.data?.data?.data);
      } catch (error) {}
      setLoading(false);
    })();
  }, [update]);

  if (unusedCoupon.res !== -2 && unusedCoupon.res !== -1) {
    unusedCoupon.res.data.data.data.filter((row) => {
      if (
        (row.mobile + "").toLowerCase().includes(searchWord) &&
        new Date().toISOString() < row.expiryDate
      ) {
        return couponsArr.push({
          no: couponsArr.length + 1,
          mid: row.mid,
          code: row.code,
          mobile: row.mobile,
          discountPrice: `$ ${row.discountPrice.toFixed(2)}`,
          expiryDate: moment(row.expiryDate).local().format("MM/DD/YYYY"),
          logo: (
            <Button
              variant="contained"
              style={
                opt_in_out[row.mobile]
                  ? {
                      background: "#f00",
                      color: "#fff",
                    }
                  : { color: "#fff" }
              }
              onClick={() =>
                setOpt_in_out((previous) => {
                  return {
                    ...previous,
                    [row.mobile]: previous[row.mobile]
                      ? !previous[row.mobile]
                      : true,
                  };
                })
              }
            >
              {" "}
              {opt_in_out[row.mobile] ? "No - Opt Out" : "Yes"}
            </Button>
          ),
          "Send remindrs": (
            <Button
              variant="contained"
              style={{ color: "#fff" }}
              onClick={() => sendMsg(row.code, row.mobile, row._id)}
            >
              Send Reminder
            </Button>
          ),
          isSended: reminderTraker[row._id] ? <Check /> : "",
        });
      } else return;
    });
  }

  const sendMsg = (code, mobile, reminderId) => {
    currentId = reminderId;
    reminderTraker[reminderId] = false;
    sendReminderAPI.apiCall({ code, mobile });
  };

  if (sendReminderAPI.res != -2 && sendReminderAPI.res != -1) {
    reminderTraker[currentId] = true;
  }

  const handleSearch = (e) => {
    setSearchWord(e.target.value);
  };

  const trafficBoosterHandler = () => {
    setLoading(true);
    setReminderTraker({});
    let LastId = "";
    (async () => {
      if (couponsArr.filter((entry) => !opt_in_out[entry.mobile]).length > 0) {
        await unusedCoupon?.res?.data?.data?.data
          .filter((entry) => !opt_in_out[entry.mobile])
          .map(async (reminderItem) => {
            if (
              (reminderItem.mobile + "")
                .toLowerCase()
                .includes(searchWord.toLowerCase()) &&
              new Date().toISOString() < reminderItem.expiryDate
            ) {
              setReminderTraker((previous) => {
                return { ...previous, [reminderItem._id]: false };
              });
              const payload = {
                code: reminderItem.code,
                mobile: reminderItem.mobile,
              };
              LastId = reminderItem._id;
              try {
                const res = await SendCoupon(payload);
                setReminderTraker((previous) => {
                  return { ...previous, [reminderItem._id]: true };
                });
                if (LastId == reminderItem._id) {
                  setLoading(false);
                  Context.setIsErrorNotification({
                    type: true,
                    message: "Traffic Booster completed",
                  });
                  updateUsageLimit();
                }
                return res;
              } catch (error) {
                if (LastId == reminderItem._id) {
                  setLoading(false);
                }
                Context.setIsErrorNotification({
                  type: false,
                  message: error?.response?.data?.message,
                });

                return error;
              }

              // sendMsg(reminderItem.code, reminderItem.mobile , reminderItem._id)
            }
          });
      } else {
        setLoading(false);
      }
    })();
  };

  return (
    <>
      <div className="main-content position-relative h-100 border-radius-lg">
        <PageHeader
          destination="Mobile Coupons"
          heading={CONSTANTS.MOBILE_COUPONS_REMINDERS}
        />
        <Warning
          isShow={isWarningShow}
          onClose={() => setIsWarningShow(false)}
          message="Traffic Boost reminder already sent - Limit 1 per customer"
          onPostiveChoice={() => setIsWarningShow(false)}
          onNegativeChoice={() => setIsWarningShow(false)}
          onPostiveText="Ok"
          onNegativeText="Deny"
          isSingleButton={true}
        />
        {unusedCoupon.loading && (
          <LinearProgress color="inherit" sx={{ margin: "0px 10px" }} />
        )}
        <div className="container-fluid py-4">
          {/* <ActionBar
            option={option}
            onChange={SelectOption}
            value={selectedValue}
            title={"Reminders"}
          /> */}
          {/* <StartDateFromDate
            SelectStartDate={SelectStartDate}
            SelectFromDate={SelectFromDate}
          /> */}
          <div class="row mt-2">
            <div class="col-sm-12 col-md-6">
              <div id="mytable_filter" class="dataTables_filter">
                <label>
                  Search:
                  <input
                    type="search"
                    class="form-control form-control-sm"
                    placeholder=""
                    onChange={handleSearch}
                    aria-controls="mytable"
                  />
                </label>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div id="mytable_filter" class="dataTables_filter">
                <button
                  className="traffic-booster-btn"
                  onClick={() => {
                    if (Limit?.traffic_booster_count > 1) {
                      setIsWarningShow(true);
                    } else {
                      trafficBoosterHandler();
                    }
                  }}
                  disabled={Loading}
                >
                  {Loading ? "Sending Message" : "Traffic Booster"}
                </button>
              </div>
              <p style={{ textAlign: "center" }}>
                Select to Resend Text Reminders Automatically*
              </p>
            </div>
          </div>
          {couponsArr.length > 0 ? (
            <div className="col-12 overflow-scroll">
              <Table headers={headers} data={couponsArr} />
            </div>
          ) : (
            <NoItemFound
              message="No reminder record found"
              style={{ marginTop: "10px" }}
            />
          )}

          <div className="col-xl-12 col-sm-12 mb-xl-0 mb-4">
            <div class="bottom_text">
              <p class="lable">
                *Note: Customers automatically receive 1 free text reminder. You
                may send 1 optional (Free) Traffic Booster text reminder per
                customer.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reminders;
