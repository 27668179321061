import React from "react";
import { Link } from "react-router-dom";

export default function PosterCategory({ label, label2, note, link }) {
  return (
    <div className="poster-box-wrapper col-lg-4 col-md-6 col-sm-12 mb-5">
      <Link to={link}>
        <div className="poster-box">
          <h3>
            {label} <br />
            {label2 && label2}
          </h3>
          <img src="assets/img/poster.png" className="img-fluid" />
          <p>View Selection</p>
        </div>
      </Link>
      <p className="note">
        <span style={{ color: "#000" }}>Note:</span> {note}
      </p>
    </div>
  );
}

PosterCategory.defaultProps = {
  label: "",
  note: "",
};
