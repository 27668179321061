import { useEffect, useState } from "react";
import Barcode from 'react-jsbarcode';
import * as html2pdf from 'html2pdf.js';
import PageHeader from "../../../components/page-header";
import { StandardRectangle } from "../../../components/avery";
import { SheetPreview } from "../../../components/avery/StandardRectangle";
import NameInsert from "./nameInsert";
import useAPI from "../../../CustomeHooks/API";
import { API_ROUTE } from "../../../utils/CONSTANTS";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
const {
  buildLabels,
  findNextLabelLocation,
} = StandardRectangle;

const labelsT = [
  { oldPrice: "120$", newPrice: "100$", name: "Apple" },
  { oldPrice: "150$", newPrice: "130$", name: "Bag" },
  { oldPrice: "100$", newPrice: "90$", name: "Candy" },
  { oldPrice: "60$", newPrice: "50$", name: "Chocolate" },
];

const DesignLabels = () => {
  const [selectedLocation, selectLocation] = useState(0);
  const [labels, setLabels] = useState(buildLabels());
  const [value, setValue] = useState(4);
  const [height, setHeight] = useState(3);
  const [width, setWidth] = useState(2.5);
  const [isBarcodeShow, setIsBarcodeShow] = useState(true);

  const labelAPI = useAPI(API_ROUTE.getInventoryLabels);

  useEffect(() => {
    labelAPI.apiCall();
  }, []);

  let initLabelsArr = [];

  if (labelAPI.res !== -2 && labelAPI.res !== -1) {
    console.log("labels", labelAPI.res);
    initLabelsArr = labelAPI.res.data.data.data;
    console.log(labelAPI.res);
  }

  useEffect(() => {
    setValue(initLabelsArr.length);
  }, [initLabelsArr]);

  useEffect(() => {
    let labelArr = {};
    initLabelsArr.forEach((lable, index) => {
      labelArr[index] = {
        name: (
          <div
            className="printable d-flex flex-column h-100"
            style={{ width: `${width}in` }}
          >
            <p className="border-bottom font-weight-bold border-1 py-3 mb-2">
              {lable.name}
            </p>
            <div className="d-flex align-items-center px-2 justify-content-center mt-2">
              <p className="font-weight-bold fs-5 me-2 mb-0">Price: </p>
              <p className="font-weight-bolder fs-4 me-2 mb-0">
                ${(lable.price / 100).toFixed(2)}
              </p>
              <p className="font-weight-normal fs-5 me-2 mb-0">
                {lable.oldPrice}
              </p>
            </div>
            {isBarcodeShow && lable.code != "" && <div className="w-90  mx-auto barcode-wrapper">
              <Barcode value={lable.code} options={{ format: 'code128', background: 'transparent', height: 0, textMargin: 0, text: lable.sku }} renderer="svg" className=" w-100 mx-auto" />
              <Barcode value={lable.code} options={{ format: 'code128', background: 'transparent', height: 80 }} renderer="svg" className="w-100  mx-auto" />
            </div>}
          </div>
        ),
      };

      selectLocation(findNextLabelLocation(selectedLocation));
    });
    console.log({ labelArr });
    setLabels(labelArr);
  }, [width, height, value, isBarcodeShow]);

  const handleNumChange = (e) => {
    if (e.target.value === 0 || e.target.value.trim() === "") {
      setValue(initLabelsArr.length);
    } else {
      setValue(e.target.value);
    }
  };

  const handlePrint = () => {
    let printContents = document.getElementById("sheet");
    const clone = printContents.cloneNode(true);
    clone.id = "extraSheet";
    let body = document.getElementsByTagName("body")[0];

    body.appendChild(clone);
    // let originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents;

    window.print();
    let removeContents = document.getElementById("extraSheet");
    console.log({ removeContents });
    removeContents.remove();
    // document.body.innerHTML = originalContents;
  };

  const handleWidth = (e) => {
    if (e.target.value === 0 || e.target.value.trim() === "") {
      setWidth(2.5);
    } else {
      setWidth(e.target.value);
    }
  };
  const handleHeight = (e) => {
    if (e.target.value === 0 || e.target.value.trim() === "") {
      setHeight(1.5);
    } else {
      setHeight(e.target.value);
    }
  };

  const handlerDownloadPDF = () => {
    const element = document.getElementById('sheet');
    const opt = {
      margin: 0,
      filename: 'labels.pdf',
      image: { type: 'jpeg', quality: 1 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
    };
    html2pdf().from(element).set(opt).save();
  }

  const handlerToggleBarcodeShow = (e) => {
    setIsBarcodeShow(e.target.checked);
  }

  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader destination={"Design Labels"} heading="Print Labels" />
      <div className="container-fluid py-4">
        <div className="d-flex align-items-center">
          <input
            placeholder="Number of cards"
            name="number"
            className="form-control w-25 me-4"
            type={"number"}
            onChange={handleNumChange}
          />
          <input
            placeholder="Enter width in inches"
            name="width"
            className="form-control w-25 me-2"
            type={"number"}
            onChange={handleWidth}
          />
          x
          <input
            placeholder="Enter height in inches"
            name="height"
            className="form-control w-25 ms-2"
            type={"number"}
            onChange={handleHeight}
          />
        </div>
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={isBarcodeShow} onChange={handlerToggleBarcodeShow} />} label="Show Barcode" />
        </FormGroup>
        <div style={{ gap: "10px", display: "flex" }}>
          <Button variant="contained" style={{
            width: '200px',
            height: '57px',
            borderRadius: '10px',
            fontSize: '17px',
            color: '#fff'
          }} onClick={handlePrint}>
            Print
          </Button>
          <Button
            variant="contained" style={{
              width: '200px',
              height: '57px',
              borderRadius: '10px',
              fontSize: '17px',
              color: '#fff',
              background: "#228800"
            }}
            onClick={handlerDownloadPDF}
          >
            Download
          </Button>
        </div>
        <SheetPreview
          className="mt-3 printable"
          id="sheet"
          height={`${height}in`}
          width={`${width}in`}
          selectedLocation={selectedLocation}
          selectLocation={selectLocation}
          numberOfSheetLocation={value}
          LabelInsertComponent={({ location }) => (
            <NameInsert {...labels[location]} />
          )}
        />

      </div>
    </div>
  );
};

export default DesignLabels;
