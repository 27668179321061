export const CONSTANTS = {
  WEBSITE_HEADER_NAME: "GivePay Admin",
  NAVIGATION_PENAL_MENU: [
    {
      id: 4,
      heading: "Business",
      subList: [
        // {
        //   id: 1,
        //   path: "/add_business",
        //   label: "Add Business",
        //   role: {
        //     admin: true,
        //   },
        // },
        {
          id: 2,
          path: "/all_business",
          label: "All Business",
          role: {
            admin: true,
          },
        },
        {
          id: 3,
          path: "/all_merchants",
          label: "All Merchants",
          role: {
            admin: true,
          },
        },
      ],

      role: {
        admin: true,
      },
    },
    {
      id: 0,
      heading: "Reporting",
      subList: [
        {
          id: 1,
          path: "/reporting_agents",
          label: "Agents / Partner",
          role: {
            admin: true,
          },
        },
        {
          id: 2,
          path: "/reporting_corporate",
          label: "Corporate",
          role: {
            admin: true,
          },
        },
        {
          id: 3,
          path: "/reporting_region",
          label: "Region / Market",
          role: {
            admin: true,
          },
        },
        {
          id: 4,
          path: "/reporting_district",
          label: "District",
          role: {
            admin: true,
          },
        },
        {
          id: 5,
          path: "/reporting_location",
          label: "Location",
          role: {
            admin: true,
            merchant: true,
          },
        },
        {
          id: 6,
          path: "/reporting_transaction",
          label: "Transaction",
          role: {
            admin: true,
            merchant: true,
          },
        },
      ],
      role: {
        admin: true,
        merchant: true,
      },
    },
    {
      id: 1,
      heading: "Mobile Coupons",
      subList: [
        {
          id: 1,
          path: "/mobilecoupons_logo",
          label: "Logo",
          role: {
            admin: true,
            merchant: true,
          },
        },
        {
          id: 2,
          path: "/mobilecoupons_expirations",
          label: "Expirations",
          role: {
            admin: true,
            merchant: true,
          },
        },
        {
          id: 3,
          path: "/mobilecoupons_reminders",
          label: "Reminders",
          role: {
            admin: true,
            merchant: true,
          },
        },
      ],
      role: {
        admin: true,
        merchant: true,
      },
    },
    {
      id: 2,
      heading: "Setup",
      subList: [
        {
          id: 1,
          path: "/setup_pricing",
          label: "Pricing",
          role: {
            admin: true,
            merchant: true,
          },
        },
        {
          id: 2,
          path: "/setup_gamifications",
          label: "Gamifications",
          role: {
            admin: true,
            merchant: true,
          },
        },
        {
          id: 3,
          path: "/setup_cashorCard",
          label: "Cash or Card",
          role: {
            admin: true,
            merchant: true,
          },
        },
        {
          id: 4,
          path: "/setup_labels",
          label: "Signage Labels",
          role: {
            admin: true,
            merchant: true,
          },
        },
        {
          id: 5,
          path: "/setup_posters",
          label: "Signage Posters",
          role: {
            admin: true,
            merchant: true,
          },
        },
        {
          id: 6,
          path: "/setup_users",
          label: "Users",
          role: {
            admin: true,
            merchant: true,
          },
        },
      ],
      role: {
        admin: true,
        merchant: true,
      },
    },
    {
      id: 3,
      heading: "Support",
      subList: [
        {
          id: 1,
          path: "/support_Contact",
          label: "Contact",
          role: {
            admin: true,
            merchant: true,
          },
        },
        {
          id: 2,
          path: "/support_faq",
          label: "FAQ",
          role: {
            admin: true,
            merchant: true,
          },
        },
      ],
      role: {
        admin: true,
        merchant: true,
      },
    },
  ],

  CONFIRM: "Confirm",
  AGENT_PARTNER: "Agent / Partner",
  CORPORATE: "Corporate",
  REGION_MARKET: "Region / Market",
  DISTRICT: "District",

  //   User Page
  TITLE: "Setup",
  SUB_TITLE: "Setup Web Dashboard Users",
  WELCOME_LINE:
    "Setup Dashboard Users with external website access - Non Point of Sale access",
  SETUP_USER: "Setup Agent / Partner / Users",
  GIVEPAY_USER: "GivePay Users",
  PLACE_HOLDERS: {
    EMAIL: "Email (User Name)",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    MOBILE_NUMBER: "Mobile Number. (2FA)",
  },
  USER_ROLE: ["Admin", "Support"],
  TWO_FACTOR_AUTH_NOTICE:
    "* I Opt-in to confirm access via 2 Factor Authentication and to receive marketing and promotional offers from GivePay and ( Your Merchant Name), Message and data rates may apply.",
  INPUT_DAY_PLACEHOLDER: "Enter number of Days",

  //   Dashboard
  DAY: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  INTERVAL: ["DAILY", "WEEKLY", "MONTHLY"],
  HAPPY: "Happy",
  lEVEL: {
    AGENT: "Agent Partner",
    COMPANY: "Company",
    REGION: "Region Market",
    DISTRICT: "District",
    LOCATION: "Location",
    TRANSACTION: "Transaction",
  },
  SEARCH_FIELD_PLACE_HOLDERS: "Search...",
  SUB_LINE:
    "Here’s a snapshot of how you’re doing this week at the Agent/Partner level",
  TOTAL_CUSTOMERS: "Total Customers",
  NEW_CUSTOMERS: "New Customers",
  RETURN_CUSTOMERS: "Return Customers",
  TOTAL_CASH_DISCOUNTS: "Total Cash Discounts",
  TOTAL_MOBILE_COUPONS: "Total Mobile Coupons",
  NEW_CUSTOMERS_SALES: "New Customers Sales",
  RETURN_CUSTOMERS_SALES: "Return Customers Sales",
  DashboardTITLE: "Dashboard Overview",
  MY_DASHBOARD: "MY DASHBOARD",

  //   Transaction Page
  TRANSACTIONS: "TRANSACTIONS",
  TIME_INTERVAL: "TIME INTERVAL ",
  TRANSACTIONS_TITLE: "Reporting Overview",
  TRANSACTION_DETAIL: "Transaction Detail",
  TRANSACTIONS_TABLE_COLUMN: [
    {
      id: 0,
      title: "Created",
    },
    {
      id: 2,
      title: "Trance ID",
    },
    {
      id: 3,
      title: "Customer",
    },
    {
      id: 4,
      title: "Discount Type",
    },
    {
      id: 5,
      title: "Transaction Total",
    },
    {
      id: 6,
      title: "Discount Total",
    },
    {
      id: 7,
      title: "Text Opt-In",
    },
    {
      id: 8,
      title: "Coupon Sent",
    },
    {
      id: 9,
      title: "Coupon Expiration",
    },
    {
      id: 10,
      title: "Scheduled Text Reminders",
    },
    {
      id: 11,
      title: "Resend Text Reminders",
    },
    {
      id: 12,
      title: "Reminder Date",
    },
  ],
  TRANSACTION_WARNING_FOR_MANAGER:
    "Transaction detail visible to managers/owners only",
  TRANSACTIONS_NOTE:
    "*Note: Mobile Coupons bill at 10¢ per message sent (limit 2 text reminders per customer per month)",

  //   Mobile Coupons -> Logo Page
  MOBILE_COUPONS_TITLE: "Mobile Coupons",
  MOBILE_COUPONS_SUB_TITLE: "Logos",
  MOBILE_COUPONS_UPLOAD_LOGO_TEXT: "Mobile Coupons - Upload Your Logo",
  MOBILE_COUPONS_PROMOTION_LINE:
    "Promote your brand with Mobile Coupons that invites customers back and promotes increased sales.",
  UPLOAD_LOGO_TEXT:
    "Upload your logo here for use with Mobile Coupons powered by GivePay",
  UPLOAD_LOGO: "Upload Logo",
  SEND_COUPON_BUTTON_TEXT: "Text Coupon*",
  SEND_COUPON_INPUT_PLACEHOLDER: "Enter Phone number",
  SEND_COUPON_LABEL_TEXT: "Text yourself to try it out*",
  SEND_COUPON_WARNING_LINE_1:
    "* I Opt-in to receive marketing and promotional offers from GivePay and ",
  SEND_COUPON_WARNING_LINE_2: ", Message and data rates may apply.",
  SEND_COUPON_NOTE:
    "Note: Use of Mobile Coupons are not included in free trial and bill at 10¢ per message sent (limit 2 reminders per customer per month)",

  // Card or coupon
  CARD_COUPPON_TITLE: "Mobile Coupons",
  CARD_COUPPON_HEADING_TEXT:
    "Upload your logo here for use with Mobile Coupons powered by GivePay",

  CARD_COUPPON_SUBLINE_TEXT:
    "Adjust your discounts for cash or card settings. \nInvite customers back with Double Discount Mobile Coupons",

  CARD_COUPPON_OPTION_TEXT_1: "Customers who select",

  CARD_COUPPON_OPTION_TEXT_2: "Pay by Card will receive a mobile ",

  CARD_COUPPON_OPTION_TEXT_3: "coupon for their next visit",

  //   Mobile Coupons -> Expirations
  MOBILE_COUPONS_EXPIRATIONS_SUB_TITLE:
    "Mobile Coupon Setup - Expiration Dates",
  MOBILE_COUPONS_EXPIRATIONS_TEXT: "Mobile Coupons - Setup Coupon Expirations",
  MOBILE_COUPONS_EXPIRATIONS_SUB_LINE:
    "Coupons can be set to expire automatically as selected below",
  SET_COUPONS_EXPIRATION_LABEL:
    "Set Coupons to expire in 7, 15, and 30 Days or select number of days",
  INVALID_DAY_WARNING: "Day must be more than 0",
  CURRENT_EXPIRY_LABEL: "Current Expiry : ",
  MOBILE_COUPONS_REMINDERS: "Manage Reminders",
  EXPIRATION_DROPDOWN: [
    {
      id: 0,
      value: "7 Days",
    },
    {
      id: 1,
      value: "15 Days",
    },
    {
      id: 2,
      value: "30 Days",
    },
  ],

  // Mobile Coupons -> Reminders
  MOBILE_COUPON_REMINDERS_TABLE_TITLE: "Select to Opt Out",
  MOBILE_COUPON_REMINDERS_SUB_TITLE: "Mobile Coupons  - Manage Reminders",
  MOBILE_COUPON_REMINDERS_NOTICE:
    "*Note: Mobile Coupons bill at 10¢ per message sent (limit 2 text reminders per customer per month)",

  // Setup -> Pricing
  PRICING_TITLE: "Pricing",
  PRICING_SUB_TITLE: "Pricing - Adjust Fees",
  ADJUST_DISCOUNTS_LINE_1: "Adjust Discounts,",
  ADJUST_DISCOUNTS_LINE_2: "Offset Credit Card Fees and Discounts",
  ADJUST_DISCOUNTS_SUBLINE_1:
    "Adjust (Increase) pricing for all Items, Inventory or Services ",
  ADJUST_DISCOUNTS_SUBLINE_2:
    "automatically to present customers with a true discount for cash",
  DISCOUNT_PERCENTAGE: "Discount Percentage",
  DISCOUNT_PERCENTAGE_RANGE: {
    START: 0,
    END: 10,
  },
  DISCOUNT_PERCENTAGE_RECOMMENDED: "Recommended : 3% to 10%",
  DISCOUNT_PERCENTAGE_SELECTED: "Selected",
  DISCOUNT_AMOUNT: "Discount Amount",
  DISCOUNT_AMOUNT_RANGE: {
    START: {
      value: 20,
      unite: "CENT",
    },
    END: {
      value: 10,
      unite: "DOLLAR",
    },
  },
  DISCOUNT_AMOUNT_RECOMMENDED: "Recommended : $0.20",
  DISCOUNT_NOTICE:
    "Adjust by % and by $ amount to match or double (offset) your credit card fees and discounts",

  // Gamification
  GAMIFICATION_TITLE: "Gamifications",
  GAMIFICATION_SUB_TITLE: "Gamify your Checkout",
  GAMIFICATION_HEADING: "Gamify your checkout experience",
  GAMIFICATION_SUB_LINE:
    "GivePay Gamifications are short (3 second) animations that present at checkout to Excite and Delight your customers with discounts designed to drive more sales and repeat visits.",
  CHECKOUT_EXPERIENCE_LABEL: "Choose your Checkout Experience",
  CHECKOUT_YOUR_GAMIFICATION: "Choose your Gamifications",
  CHECKOUT_EXPERIENCE_LIST: [
    {
      id: 0,
      label: "Default (Universal) Gamifications",
      cloverImageUrl: "assets/img/c1.png",
      mobileImageUrl: "assets/img/c2.png",
    },
    {
      id: 1,
      label: "Default (Universal) Gamifications",
      cloverImageUrl: "",
      mobileImageUrl: "",
    },
    {
      id: 2,
      label: "Default (Universal) Gamifications",
      cloverImageUrl: "",
      mobileImageUrl: "",
    },
    {
      id: 3,
      label: "Default (Universal) Gamifications",
      cloverImageUrl: "",
      mobileImageUrl: "",
    },
    {
      id: 4,
      label: "Default (Universal) Gamifications",
      cloverImageUrl: "",
      mobileImageUrl: "",
    },
  ],
  GAMIFY_ON: "Gamify On",
  GAMIFY_OFF: "Gamify Off",
  CHOOSE_YOUR_GAMIFICATIONS: "Choose your Gamifications",
  POS_GAMIFICATIONS: [
    {
      id: 0,
      type: "Confetti",
      typeColor: "#E900FF",
      imageUrl: "assets/img/g1.png",
      subscription: "Default",
    },
    {
      id: 1,
      type: "Fireworks",
      typeColor: "#1D94E4",
      imageUrl: "assets/img/g1.png",
      subscription: "Comming Soon",
    },
    {
      id: 2,
      type: "$ale Signs",
      typeColor: "#FF6233",
      imageUrl: "assets/img/g2.png",
      subscription: "Comming Soon",
    },
    {
      id: 3,
      type: "Holiday Wreaths",
      typeColor: "#228800",
      imageUrl: "assets/img/g1.png",
      subscription: "Comming Soon",
    },
  ],

  MOBILE_GAMIFICATIONS: [
    {
      id: 0,
      type: "Fireworks",
      typeColor: "#1D94E4",
      imageUrl: "assets/img/gm3.png",
      subscription: "Default",
    },
    {
      id: 1,
      type: "Confetti",
      typeColor: "#E900FF",
      imageUrl: "assets/img/gm1.png",
      subscription: "Comming Soon",
    },
    {
      id: 2,
      type: "$ale Signs",
      typeColor: "#FF6233",
      imageUrl: "assets/img/gm2.png",
      subscription: "Comming Soon",
    },
    {
      id: 3,
      type: "Holiday Wreaths",
      typeColor: "#228800",
      imageUrl: "assets/img/gm3.png",
      subscription: "Comming Soon",
    },
  ],

  // Setup -> Signage -> Labels
  POSTER_TITLE: "Posters",
  POSTER_SUB_TITLE: "Print custom ;Pricing Labels with  AveryTM  and GivePayTM",
  POSTER_CASH_DISCOUNT: "Cash Discount Only Posters",
  POSTER_CASH_DISCOUNT_NOTE:
    "Note:   Cash Discount Only Posters inform customers that they will receive a Double Cash Discount with their next Cash purchase / visit.",
  POSTER_CASH_CARD_DISCOUNT: "Cash or Card Discount Posters",
  POSTER_CARD_DISCOUNT_NOTE:
    "Note:   Cash or Card Discount Posters inform customers that they will receive a Double Cash or Card Discount with their next purchase / visit.",
  POSTER_CTA_TEXT: "View Selection",
  POSTER_CATEGORY: [
    {
      id: 0,
      label: "Cash Discount Only",
      label2: "Posters",
      link: "/posters/Cash Discount",
      note: "Cash Discount Only Posters inform customers that they will receive a Double Cash Discount with their next Cash purchase / visit.",
    },
    {
      id: 1,
      label: "Cash or Card",
      label2: "Discount Posters",
      link: "/posters/Cash or Card",
      note: "Cash Discount Only Posters inform customers that they will receive a Double Cash Discount with their next Cash purchase / visit.",
    },
  ],

  API_NAME: {
    "Cash Discount": "card",
    "Cash or Card": "cash",
  },

  RESPONSE_STATUS: {
    success: true,
  },

  MID: "QZ8JPK37NFM71",

  LABEL_TITLE: "Labels",
  LABEL_SUB_TITLE: "Print custom Pricing Labels with  AveryTM  and GivePayTM",
  LABEL_SUB_TITLE_2: "Every design & print online guide",
  LABEL_LINE_1:
    "Choose a blank or pre-designed free template, then add text and images.",
  LABEL_LINE_2: " Avery Design & Print - Design. Print. Go!™",
  LABEL_LINE_3:
    " With GivePay and Avery Design & Print, creating quality pricing labels has never been easier.",
  LABEL_LINE_4:
    " Just choose a template size to print pricing, or select Start Designing to customize your print size, then print it yourself or let Avery print it for you.",
  LABEL_CTA_TEXT: "Start Designing",
  WE_PRINT_TEXT: "WePrint",

  LABELS: [
    {
      id: 0,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: "",
      ratingData: "",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 1,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: "",
      ratingData: "",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 2,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: 5,
      ratingData: "4.5 (1)",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 3,
      label: "Looking for our In-store Packs?",
      link: "assets/img/store.jpg",
      size: "",
      rating: "",
      ratingData: "",
      note: "Purchase online or in stores",
    },
    {
      id: 4,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: "",
      ratingData: "",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 5,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: "",
      ratingData: "",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 6,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: 5,
      ratingData: "4.5 (1)",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 7,
      label: "Looking for our In-store Packs?",
      link: "assets/img/store.jpg",
      size: "",
      rating: "",
      ratingData: "",
      note: "Purchase online or in stores",
    },
  ],
  LABEL_FREE_TEMPLATE:
    "Personalize one of our proffessional templates with our large clipart & font library, or upload your own artwork.",
  LABEL_BARCODE:
    " Create unique barcodes to add to product packaging labels or add sequential numbering to raffle tickets & price tag.",
  CONTACT_URL: "https://www.givepay.com/setup-and-support",
  TERMS_OF_USAGE_URL: "https://www.givepay.com/terms",
  FAQ_URL: "https://www.givepay.net/pricing/",
  ADD_BUSSINESS_HEADING: "Add Business",
  ADD_BUSSINESS_LINE:
    "Setup Dashboard Users with external website access - Non Point of Sale access",
  ALL_BUSSINESS_HEADING: "All Business",
  ALL_MERCHANTS_HEADING: "All MERCHANTS",
};

export const API_ROUTE = {
  login: { type: "post", url: "/auth/login" },
  forgot: { type: "post", url: "/auth/password/forgot" },
  updatePassword: { type: "patch", url: "/auth/password" },
  getMe: { type: "get", url: "/profile" },
  updateMe: { type: "patch", url: "/merchants" },
  addAgent: { type: "post", url: "/merchants" },
  addBusiness: { type: "post", url: "/businesses" },
  deleteBusiness: { type: "delete", url: "/businesses" },
  getBusinesses: { type: "get", url: "/businesses" },
  getAllTransaction: { type: "get", url: "/transctions" },
  getLogo: { type: "get", url: "/coupons/logos" },
  sendCoupon: { type: "post", url: "/coupons" },
  updateMerchant: { type: "post", url: "/merchants" },
  sendReminder: { type: "post", url: "/coupons/reminder" },
  getAllPosterByCategory: { type: "get", url: "/posters/type/card" },
  getAllMerchants: { type: "get", url: "/merchants" },
  getMerchantByEmail: { type: "post", url: "/merchants/email" },
  setDiscountOnInventory: { type: "patch", url: "/inventories/setdiscount/" },
  getUnusedCoupons: { type: "get", url: "/coupons/unused" },
  sendReminderMsg: { type: "post", url: "/coupons/reminder" },
  getInventoryLabels: { type: "post", url: "/inventories/items" },
  getMerchantsByBusiness: { type: "post", url: "/merchants/bid" },
  updateMerchantToken: { type: "post", url: "/merchants/update-token" },
};

export const NOTIFICATION_MESSAGE = {
  InValidId: { type: false, message: "Invalid ImageID & Password" },
  LOGIN_SUCCESS: { type: true, message: "Login Success" },
  MSG_SEND: { type: true, message: "Message sent!" },
  ADD_SUCCESS: { type: true, message: "Added" },
  UPDATE_PASS_SUCCESS: { type: true, message: "User Password Updated" },
  UPDATE_SUCCESS: { type: true, message: "Updated" },
  SOMETHING_WRONG: { type: false, message: "Something Went Wrong" },
};

export const COLOR = {
  THEME: {
    primary: "#1D94E6",
    secondory: "#228800",
  },
};

export const USER_DATA = {
  user: {},
};
