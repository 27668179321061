import moment from "moment";
import { formatNumber } from "../../../utils/storage";
import "./progressCard.css";

const fistDate = new Date();
fistDate.setDate(fistDate.getDate() - 7);



const getTitle = {
  0: "Today",
  1: "This Week",
  2: "This Month",
  3: "-",
}



const ProgressCard = ({ totalSell, timeInterVal, startDate, endDate }) => {
  const getTime = {
    0: moment(new Date()).local().format("MMM-DD"),
    1: moment(fistDate).local().format("MMM-DD") + " to " + moment(endDate).local().format("MMM-DD"),
    2: moment(new Date()).local().format("MMM"),
    3: moment(startDate).local().format("MMM-DD") + " to " + moment(endDate).local().format("MMM-DD"),
  }
  return (
    <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
      <div className="card dashboardcards">
        <div className="card-body p-3">
          <div className="graph">
            <div
              className="semi-donut-model-2 margin"
              style={{ "--percentage": 50, "--fill": "#039BE5" }}
            >
              <p>{getTitle[timeInterVal]}</p>
              <p>{getTime[timeInterVal]}</p>
              <span className="orange-color">-</span>
              <h3 className="number bottom-number"> ${formatNumber(+totalSell.toFixed(2))}</h3>
              <span className="orange-color goal">-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressCard;
