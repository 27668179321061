import PageHeader from "../../../components/page-header";
import MobilePic from "../../../assets/img/mobile.png";
import { API_ROUTE, COLOR, CONSTANTS } from "../../../utils/CONSTANTS";
import "./expirations.css";
import { useContext, useEffect, useState } from "react";
import { GetExpiryDay, UpDateExpiry } from "../../../api/allServices";
import { LinearProgress } from "@mui/material";
import useAPI from "../../../CustomeHooks/API";
import { authStorage } from "../../../utils/login";
import { NavConstant } from "../../../store/context";
let currentExpiryDay = -1;
let expiryDay = "-1";
const Expirations = () => {
  const [showInput, setShowInput] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const navContext = useContext(NavConstant);
  const getMyProfile = useAPI(API_ROUTE.getMe);
  const updateProfile = useAPI(API_ROUTE.updateMe);
  console.log("first");
  const handleChange = (e) => {
    console.log(e.target.value);
    expiryDay = e.target.value;
    console.log(expiryDay);
    if (e.target.value === "-1") {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  };

  useEffect(() => {
    getMyProfile.apiCall();
  }, []);

  if (getMyProfile.res != -2 && getMyProfile.res != -1) {
    if (authStorage.role !== "merchant") {
      currentExpiryDay = navContext.merchantCouponExpire;
    } else {
      currentExpiryDay = getMyProfile.res?.data?.data?.data?.coupon_expires_in;
    }
  }

  if (updateProfile.res != -2 && updateProfile.res != -1 && !showInput) {
    currentExpiryDay = expiryDay.replace(" Days", "");
  }

  const updateExpiryHandler = () => {
    if (expiryDay.replace(" Days", "") - 1 < 0) {
      setIsInvalid(true);
      return;
    } else {
      setIsInvalid(false);
    }
    setShowInput(false);
    const payload = {
      coupon_expires_in: expiryDay.replace(" Days", ""),
    };
    console.log(payload);
    updateProfile.apiCall(payload);
  };

  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader
        destination="Mobile Coupons"
        heading={CONSTANTS.MOBILE_COUPONS_EXPIRATIONS_TEXT}
      />
      {(getMyProfile.loading || updateProfile.loading) && (
        <LinearProgress color="inherit" sx={{ margin: "0px 10px" }} />
      )}
      <div className="container-fluid py-4">
        <div className="row content-wrapper ">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div class="setup-form mt-3">
              <p>{CONSTANTS.MOBILE_COUPONS_EXPIRATIONS_SUB_LINE}</p>
              <form className="small-form mt-3 mb-5" >
                <p className="upload-text">
                  {CONSTANTS.SET_COUPONS_EXPIRATION_LABEL}
                </p>

                <p className="upload-text" style={{ fontWeight: '800' }}>
                  {CONSTANTS.CURRENT_EXPIRY_LABEL +
                    (!(getMyProfile.loading || updateProfile.loading)
                      ? currentExpiryDay + " Days"
                      : "Loading....")}
                </p>

                <div class="form-row mt-4">
                  <div class="form-group col-lg-8 col-md-12 col-sm-12">
                    <div class="input-group join-drop">
                      <div class="input-group-prepend">
                        <select
                          id="mySelect"
                          className="logo-inputField"
                          onChange={handleChange}
                        >
                          {CONSTANTS.EXPIRATION_DROPDOWN.map((expiration) => (
                            <option
                              key={expiration.id}
                              value={expiration.value}
                            >
                              {expiration.value}
                            </option>
                          ))}

                          <option value="-1">Enter number of Days</option>
                        </select>
                      </div>
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline"
                          type="button"
                          onClick={updateExpiryHandler}
                          style={{
                            background: COLOR.THEME.primary,
                            borderBottom: '1px solid #707070',
                            borderRight: '1px solid #707070',
                            borderTop: '1px solid #707070'
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {showInput && (
                  <div id="other-div" className="mt-3">
                    <input
                      type="number"
                      id="other-input"
                      placeholder="Enter number of Days"
                      min={1}
                      required
                      onChange={(e) => (expiryDay = e.target.value)}
                    ></input>
                    {isInvalid && (
                      <p className="upload-text" style={{ color: "red" }}>
                        {CONSTANTS.INVALID_DAY_WARNING}
                      </p>
                    )}
                  </div>
                )}
              </form>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img src={MobilePic} alt="" className="img-fluid mobile-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expirations;
