import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useAPI from "../CustomeHooks/API";
import { NavConstant } from "../store/context";
import { API_ROUTE, USER_DATA } from "../utils/CONSTANTS";
import { authStorage } from "../utils/login";
import { Tooltip } from "@mui/material";
import LogOutIcon from '../images/logout.png'
import { useState } from "react";
import HandleToken from "../components/token_handle"
import axios from "axios"

import { CLOVER_END_POINT, CLOVER_APP_ID, PUBLIC_URL, CLOVER_TOKEN_END_POINT, CLOVER_APP_SECRET } from "../utils/urls";


export default function PageHeader({ destination, heading, subDestination }) {
  const navContext = useContext(NavConstant);
  const logOutHandler = () => {
    authStorage.deleteAuthDetails();
    navContext.checkIsLogin();
    localStorage.setItem("lastMID", "")
  };

  const merchantAPI = useAPI(API_ROUTE.getAllMerchants);
  const merchantByBussAPI = useAPI(API_ROUTE.getMerchantsByBusiness);
  const [merchantaArr, setMerchantaArr] = useState([])

  const updateMerchantTokenAPI = useAPI(API_ROUTE.updateMerchantToken)

  const [newAccessToken, setNewAccessToken] = useState("")
  const [autherizarionCode, setAutherizarionCode] = useState("")

  const [isShow, setIsShow] = useState(false)
  const handleCloseToken = () => {
    setIsShow(false)
  }



  useEffect(() => {
    if (authStorage.role !== "merchant") {

      if (authStorage.role === "super-admin") {
        merchantAPI.apiCall();
      } else if (authStorage.role === "admin") {
        USER_DATA?.user?.businessId && merchantByBussAPI.apiCall({ businessId: USER_DATA?.user?.businessId })
      }

    } else if (authStorage.role === "merchant" && USER_DATA.user && USER_DATA.user.mid && USER_DATA.user?.isTokenExpired === 1 && pathname != "/oauth-callback") {
      // setIsShow(true)
    }
  }, []);


  useEffect(() => {

    if (merchantAPI.res !== -2 && merchantAPI.res !== -1) {
      let resData = merchantAPI.res.data.data.data;

      const lastMID = localStorage.getItem("lastMID");
      let merchants = resData.map((row, index) => {

        if (!navContext?.merchantId && ((lastMID && lastMID == row.mid) || (!lastMID && index === 0))) {

          navContext.setMerchantId(row.mid);
          navContext.setMerchantEmail(row.email);
          navContext.setIsMerchantTokenExpired(row?.isTokenExpired);
          navContext.setMerchantCouponExpire(row.coupon_expires_in);
          authStorage.setMToken(row.access_token);
          authStorage.setMID(row.mid);

          localStorage.setItem("lastMID", row.mid);
        }
        return {
          mid: row.mid,
          name: row.firstname + " " + row.lastname,
        };
      });
      setMerchantaArr(merchants)
    }
  }, [merchantAPI.res])

  useEffect(() => {

    if (merchantByBussAPI.res !== -2 && merchantByBussAPI.res !== -1) {
      let resData = merchantByBussAPI.res.data.data.data;

      const lastMID = localStorage.getItem("lastMID");

      let merchants = resData.map((row, index) => {

        if (!navContext?.merchantId && ((lastMID && lastMID == row.mid) || (!lastMID && index === 0))) {
          navContext.setMerchantId(row.mid);
          navContext.setMerchantEmail(row.email);

          navContext.setIsMerchantTokenExpired(row?.isTokenExpired);
          navContext.setMerchantCouponExpire(row.coupon_expires_in);
          authStorage.setMToken(row.access_token);
          authStorage.setMID(row.mid);

          localStorage.setItem("lastMID", row.mid);
        }
        return {
          mid: row.mid,
          name: row.firstname + " " + row.lastname,
        };
      });
      setMerchantaArr(merchants)
    }
  }, [merchantByBussAPI.res])

  const setNewMerchantId = (id) => {
    if (merchantAPI.res !== -2 && merchantAPI.res !== -1) {
      let merchantSelected = merchantAPI.res.data.data.data.filter(
        (merchant) => merchant.mid === id,
      )[0];
      navContext.setMerchantId(merchantSelected.mid);
      navContext.setMerchantEmail(merchantSelected.email);
      navContext.setIsMerchantTokenExpired(merchantSelected?.isTokenExpired);
      navContext.setMerchantCouponExpire(merchantSelected.coupon_expires_in);
      authStorage.setMID(id);
      authStorage.setMToken(merchantSelected.access_token);

      localStorage.setItem("lastMID", id);
    } else if (merchantByBussAPI.res !== -2 && merchantByBussAPI.res !== -1) {
      let merchantSelected = merchantByBussAPI.res.data.data.data.filter(
        (merchant) => merchant.mid === id,
      )[0];
      navContext.setMerchantId(merchantSelected.mid);
      navContext.setMerchantEmail(merchantSelected.email);
      navContext.setIsMerchantTokenExpired(merchantSelected?.isTokenExpired);
      navContext.setMerchantCouponExpire(merchantSelected.coupon_expires_in);
      authStorage.setMID(id);
      authStorage.setMToken(merchantSelected.access_token);

      localStorage.setItem("lastMID", id);
    }
  };


  const { pathname } = useLocation();

  useEffect(() => {
    if (navContext.merchantId) {

      if (navContext.isMerchantTokenExpired && pathname != "/oauth-callback") {
        // setIsShow(true)
      }
    }
  }, [navContext.merchantId])


  useEffect(() => {

    if (pathname === "/oauth-callback") {

      const authResult = new URLSearchParams(window.location.search);
      const code = authResult.get('code')

      setAutherizarionCode(code);
    }
  }, [])


  useEffect(() => {

    if (autherizarionCode) {
      const options = {
        method: 'POST',
        data: {
          "client_id": CLOVER_APP_ID,
          "client_secret": CLOVER_APP_SECRET,
          "code": autherizarionCode
        },
        url: `${CLOVER_TOKEN_END_POINT}`,
      };

      axios
        .request(options)
        .then(function (response) {
          if (response.status === 200) {

            let access_token = response.data.access_token;
            setNewAccessToken(access_token)
          }

        })
        .catch(function (error) {
          console.log('Error token');
          // console.error(error);
        });

    }

  }, [autherizarionCode])

  useEffect(() => {

    if (newAccessToken) {
      const merchantId = authStorage.role === "merchant" ? USER_DATA.user.mid : navContext.merchantId;

      const token_body = {
        mid: merchantId,
        new_access_token: newAccessToken,
        isTokenExpired: 0
      }
      // console.log("token_body: ", token_body)
      updateMerchantTokenAPI.apiCall(token_body)
    }

  }, [newAccessToken])

  const fetchNewToken = () => {


    const merchantId = authStorage.role === "merchant" ? USER_DATA.user.mid : navContext.merchantId;
    let token_code_auth_url = `${CLOVER_END_POINT}/oauth/authorize?client_id=${CLOVER_APP_ID}&response_type=code&redirect_uri=${PUBLIC_URL}/oauth-callback&merchant_id=${merchantId}`;

    window.location.href = (token_code_auth_url)
  }

  useEffect(() => {

    if (updateMerchantTokenAPI.res !== -2 && updateMerchantTokenAPI.res !== -1) {
      window.location.href = PUBLIC_URL;
    }
  }, [updateMerchantTokenAPI.res])


  return (


    <nav
      className="navbar navbar-main navbar-expand-lg px-0 mx-2 shadow-none border-radius-xl"
      id="navbarBlur"
      navbar-scroll="true"
    >


      <HandleToken isShow={isShow} onClose={handleCloseToken} fetchNewToken={fetchNewToken} />
      <div className="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm">
              <Link className="opacity-5 text-dark" to="/">
                Home
              </Link>
            </li>
            {destination && (
              <li
                className="breadcrumb-item text-sm text-dark active"
                aria-current="page"
              >
                {destination}
              </li>
            )}
            {subDestination && (
              <li
                className="breadcrumb-item text-sm text-dark active"
                aria-current="page"
              >
                {subDestination}
              </li>
            )}
          </ol>
          <h6 className="font-weight-bolder mb-0 page_title">{heading}</h6>
        </nav>
        <div className="d-flex">
          {(USER_DATA.user?.role?.toLowerCase() === "admin" || USER_DATA.user?.role?.toLowerCase() === "super-admin") && (
            <div className="me-2">
              {merchantaArr.length > 0 && (
                <select className="time_interval_option" onChange={(e) => setNewMerchantId(e.target.value)}>
                  {merchantaArr.map((merchant) => (
                    <option
                      value={merchant.mid}
                      selected={navContext?.merchantId == merchant.mid}
                    >
                      {merchant.name} ({merchant.mid}){" "}
                    </option>
                  ))}
                </select>
              )}
            </div>
          )}
          <div className="nav-item  ps-3 d-flex align-items-center">
            <div className="Log-out-wrapper" onClick={logOutHandler}>
              <Tooltip title="Log Out">
                <div>
                  Log Out &nbsp; <img src={LogOutIcon} title="LogOut" height=" 19px" />
                </div>
              </Tooltip>
            </div>

            <a
              className="nav-link d-xl-none text-body p-0"
              id="iconNavbarSidenav"
            >
              <div
                className="sidenav-toggler-inner"
                onClick={() =>
                  navContext.setIsNavigationOverLap(
                    navContext.isNavigationOverLap,
                  )
                }
              >
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
