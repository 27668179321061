import { TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import PageHeader from "../../../components/page-header";
import Slider from "../../../components/Ui/slider";
import Warning from "../../../components/Ui/warning";
import useAPI from "../../../CustomeHooks/API";
import { NavConstant } from "../../../store/context";
import {
  API_ROUTE,
  COLOR,
  CONSTANTS,
  NOTIFICATION_MESSAGE,
  USER_DATA,
} from "../../../utils/CONSTANTS";
import "./pricing.css";

const Pricing = () => {
  const [clientPercent, setClientPercent] = useState(0);
  const [clientAmount, setClientAmount] = useState(0);
  const [isWarningShow, setIsWarningShow] = useState(false);

  const navContext = useContext(NavConstant);

  const getMerchant = useAPI(API_ROUTE.getMerchantByEmail);

  useEffect(() => {
    if (navContext.merchantEmail) {
      getMerchant.apiCall({ email: navContext.merchantEmail });
    } else {
      setClientAmount(+USER_DATA?.user?.discount_amount / 100);
      setClientPercent(USER_DATA?.user?.discount_percentage);
    }
  }, [navContext.merchantEmail, USER_DATA.user]);

  let arr = [];

  if (getMerchant.res !== -2 && getMerchant.res !== -1) {
    arr = getMerchant.res;
  }

  const updateDiscount = useAPI(
    API_ROUTE.setDiscountOnInventory,
    NOTIFICATION_MESSAGE.UPDATE_SUCCESS
  );

  const handleChangePercentage = (e) => {
    setClientPercent(+e.target.value);
  };

  const handleChangeAmount = (e) => {
    setClientAmount(+e.target.value / 100);
  };

  const handleChangeDiscount = (e) => {
    e.preventDefault();
    setIsWarningShow(false);
    updateDiscount.apiCall({
      discount_amount: parseInt(
        clientAmount * 100 || arr?.data?.data?.data?.discount_amount
      ),
      discount_percentage:
        +clientPercent || +arr?.data?.data?.data?.discount_percentage,
    });
  };

  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader
        destination="Adjust Discounts"
        heading={CONSTANTS.PRICING_TITLE}
      />
      <Warning
        isShow={isWarningShow}
        onClose={() => setIsWarningShow(false)}
        message="This Action will change your all product pricing in your inventory. Are sure want to perform this action?"
        onPostiveChoice={handleChangeDiscount}
        onNegativeChoice={() => setIsWarningShow(false)}
        onPostiveText="Agree"
        onNegativeText="Deny"
      />

      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-xl-12 col-sm-12 mb-xl-0 mb-1">
            <h3>{CONSTANTS.ADJUST_DISCOUNTS_LINE_2}</h3>
            <div className="hello_text">
              <p className="lable">
                {CONSTANTS.ADJUST_DISCOUNTS_SUBLINE_1}
                <br />
                {CONSTANTS.ADJUST_DISCOUNTS_SUBLINE_2}
              </p>
            </div>
          </div>

          <div className="col-xl-12 col-sm-12">
            <form className="small-form mt-0">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <TextField
                    error={false}
                    id="outlined-error"
                    label="Enter Discount Percentage"
                    value={+clientPercent.toFixed(2)}
                    style={{ marginBottom: "17px" }}
                    type={"number"}
                    onChange={(e) => {
                      if (e.target.value > 10 || e.target.value < 0) {
                        navContext.setIsErrorNotification({
                          type: false,
                          message:
                            "The discount percentage should be between 0.00% to 10.00%",
                        });
                      } else {
                        handleChangePercentage(e);
                      }
                    }}
                  />
                  <div className="range-box">
                    <label for="customRange1" className="form-label">
                      {CONSTANTS.DISCOUNT_PERCENTAGE}
                    </label>
                    <Slider
                      id="formControlRange"
                      onChange={handleChangePercentage}
                      value={clientPercent.toFixed(2) || 0}
                      min={0}
                      max={10}
                    />
                    <div className="row">
                      <div className="col-6 ">
                        <p>
                          <span id="client1" className="font-weight-bold">
                            0%
                          </span>
                        </p>
                      </div>
                      <div className="col-6 text-right ">
                        <p>
                          <span id="company1" className="font-weight-bold">
                            10%
                          </span>
                        </p>
                      </div>
                    </div>

                    <h3>
                      <span>
                        {clientPercent.toFixed(2) ||
                          arr?.data?.data?.data?.discount_percentage ||
                          0}
                        %
                      </span>{" "}
                      Selected
                    </h3>
                    <p className="small-text">
                      {CONSTANTS.DISCOUNT_PERCENTAGE_RECOMMENDED}
                    </p>
                  </div>
                </div>

                <div className="col-lg-1 col-md-1 col-sm-12">
                  <p className="plus">+</p>
                </div>

                <div className="col-lg-5 col-md-5 col-sm-12">
                  <TextField
                    error={false}
                    id="outlined-error"
                    label={"Enter " + CONSTANTS.DISCOUNT_AMOUNT}
                    value={+clientAmount}
                    style={{ marginBottom: "17px" }}
                    type={"number"}
                    onChange={(e) => {
                      if (e.target.value > 1 || e.target.value < 0) {
                        navContext.setIsErrorNotification({
                          type: false,
                          message:
                            "The discount Amount should be between $0.00 to $10.00",
                        });
                      } else {
                        handleChangeAmount(e);
                      }
                    }}
                  />
                  <div className="range-box">
                    <label for="customRange1" className="form-label">
                      {CONSTANTS.DISCOUNT_AMOUNT}
                    </label>
                    <Slider
                      id="formControlRange1"
                      onChange={handleChangeAmount}
                      value={clientAmount * 100 || 0}
                      min={0}
                      max={100}
                    />

                    <div className="row">
                      <div className="col-6 ">
                        <p>
                          <span id="client1" className="font-weight-bold">
                            $0.00
                          </span>
                        </p>
                      </div>
                      <div className="col-6 text-right ">
                        <p>
                          <span id="company1" className="font-weight-bold">
                            $1.00
                          </span>
                        </p>
                      </div>
                    </div>

                    <h3>
                      <span>
                        {clientAmount
                          ? clientAmount === 100
                            ? "$1.00"
                            : `$${clientAmount.toFixed(2)}`
                          : arr?.data?.data?.data?.discount_amount
                          ? `${arr?.data?.data.data.discount_amount}¢`
                          : "$0.00"}
                      </span>{" "}
                      Selected
                    </h3>
                    <p className="small-text">
                      {CONSTANTS.DISCOUNT_AMOUNT_RECOMMENDED}
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
                  <div className="hello_text_pricing">
                    <p className="lable">{CONSTANTS.DISCOUNT_NOTICE}</p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <button
                    type="submit"
                    className="btn pricing-confirm-btn"
                    style={{
                      backgroundColor: updateDiscount.loading
                        ? COLOR.THEME.secondory
                        : COLOR.THEME.primary,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsWarningShow(true);
                    }}
                    disabled={updateDiscount.loading}
                  >
                    {updateDiscount.loading
                      ? "Updating inventory..."
                      : "Sync Inventory"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
