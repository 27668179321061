import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { NavConstant } from "../../store/context";

export default function NavbarSubListItem({ item }) {
  const navContext = useContext(NavConstant);

  return (
    <Link
      to={item.path}
      className="nav-link"
      onClick={() => navContext.setIsNavigationOverLap(true)}
    >
      <span className="nav-link-text ms-1">{item.label}</span>
    </Link>
  );
}

NavbarSubListItem.defaultProps = {
  item: {
    path: "/",
    label: "home",
  },
};
