import PageHeader from "../../../components/page-header";
import { CONSTANTS } from "../../../utils/CONSTANTS";

const GamificationInner = () => {
  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader
        destination={CONSTANTS.GAMIFICATION_TITLE}
        heading={CONSTANTS.GAMIFICATION_TITLE}
      />
      <div className="row justify-content-center">
        {CONSTANTS.POS_GAMIFICATIONS.map((gamification) => {
          return (
            <div
              className="col-lg-3 col-md-6 col-sm-12 mt-3"
              key={gamification.id}
            >
              <div className="game-box">
                <h3 className="mb-3">
                  POS Gamifications
                  <span style={{ color: gamification.typeColor }}>
                    {gamification.type}
                  </span>
                </h3>
                <img
                  src={gamification.imageUrl}
                  className="img-fluid mb-3"
                  alt="POS_Gamification"
                />
                <p className="caption">{gamification.subscription}</p>
              </div>
            </div>
          );
        })}
        {CONSTANTS.MOBILE_GAMIFICATIONS.map((gamification) => {
          return (
            <div
              className="col-lg-3 col-md-6 col-sm-12 mt-3"
              key={gamification.id}
            >
              <div className="game-box">
                <h3 className="mb-3">
                  Mobile Gamifications
                  <span style={{ color: gamification.typeColor }}>
                    {gamification.type}
                  </span>
                </h3>
                <img
                  src={gamification.imageUrl}
                  className="img-fluid mb-3"
                  alt="Mobile_Gamification"
                />
                <p className="caption">{gamification.subscription}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default GamificationInner;
