import React from "react";
import PageHeader from "../../components/page-header";
import { CONSTANTS } from "../../utils/CONSTANTS";
import Ifream from "./ifream";

export default function TermsOfUsage() {
  return (
    <>
      <PageHeader destination="Support" heading="Terms of Use" />
      <Ifream src={CONSTANTS.TERMS_OF_USAGE_URL} />
    </>
  );
}
