import React, { useState } from "react";
import { authStorage } from "../utils/login";

export const NavConstant = React.createContext({
  isNavigationOverLap: false,
  Error: { isShow: false, isError: false, message: "" },
  merchantId: "",
  merchantEmail: "",
  merchantCouponExpire: "",
  IsLogin: false,
  isMerchantTokenExpired: "",
  setIsNavigationOverLap: (state) => { },
  setIsErrorNotification: (state) => { },
  checkIsLogin: (state) => { },
  setMerchantId: (state) => { },
  setMerchantEmail: (state) => { },
  setMerchantCouponExpire: (state) => { },
  setIsMerchantTokenExpired: (state) => { },
});

const NavProvider = (props) => {
  const [isNavigationOverLap, setIsNavigationOverlap] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  const [merchantEmail, setMerchantEmail] = useState("");
  const [merchantCouponExpire, setMerchantCouponExpire] = useState("");
  const [isMerchantTokenExpired, setIsMerchantTokenExpired] = useState("");
  const [IsLogin, setIsLogin] = useState(authStorage.authToken ? true : false);
  const [Error, setError] = useState({
    isShow: false,
    isError: false,
    message: "Message",
  });

  const setIsNavigationOverLap = (state) => {
    setIsNavigationOverlap(!state);
  };

  const setIsErrorNotification = (state) => {
    setError({ isShow: true, isError: state.type, message: state.message });
    setTimeout(() => {
      setError({ isShow: false, isError: false, message: state.message });
    }, 1500);
  };

  const checkIsLogin = () => {
    if (authStorage.authToken) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  };

  const context = {
    isNavigationOverLap: isNavigationOverLap,
    Error: Error,
    IsLogin: IsLogin,
    merchantId: merchantId,
    merchantEmail: merchantEmail,
    merchantCouponExpire: merchantCouponExpire,
    isMerchantTokenExpired: isMerchantTokenExpired,
    setMerchantEmail: setMerchantEmail,
    setMerchantId: setMerchantId,
    setMerchantCouponExpire: setMerchantCouponExpire,
    checkIsLogin: checkIsLogin,
    setIsNavigationOverLap: setIsNavigationOverLap,
    setIsErrorNotification: setIsErrorNotification,
    setIsMerchantTokenExpired: setIsMerchantTokenExpired
  };

  return (
    <NavConstant.Provider value={context}>
      {props.children}
    </NavConstant.Provider>
  );
};

export default NavProvider;
