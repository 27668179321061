import { Link } from "react-router-dom";
import PageHeader from "../../../components/page-header";
import { CONSTANTS } from "../../../utils/CONSTANTS";
import "./labels.css";

const Labels = () => {
  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader destination={"Labels"} heading="Pricing Labels" />
      <div className="container-fluid py-4">
        <h3 className="subHeading">{CONSTANTS.LABEL_SUB_TITLE}</h3>
        <div className="row justify-content-center labels">
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <img src="assets/img/label-logo.png" className="img-fluid logo" />
              <div className="hello_text hello_text_label">
                <p className="lable">{CONSTANTS.LABEL_LINE_1}</p>
                <p className="lable">{CONSTANTS.LABEL_LINE_2}</p>
                <p className="lable">{CONSTANTS.LABEL_LINE_3}</p>
                <p className="lable">{CONSTANTS.LABEL_LINE_4}</p>
              </div>
              <div style={{ display: "flex" }}>
                <button id="startDesigningbtn" type="submit" className="btn btn-primary btn-design">
                  <Link to={"/design_labels"}>{CONSTANTS.LABEL_CTA_TEXT}</Link>
                </button>
                <form action="https://www.averyproducts.com.au/weprint">
                  <button id="startDesigningbtn" className="btn btn-primary btn-design ms-2">
                    {CONSTANTS.WE_PRINT_TEXT}
                  </button>
                </form>
              </div>


            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="youtube-video">
                <div className="yheader">
                  <h3>{CONSTANTS.LABEL_SUB_TITLE_2}</h3>
                  <button
                    type="button"
                    className="close label-close"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <iframe
                  width="560"
                  height="290"
                  src="https://www.youtube.com/embed/hI-L0GNKSRY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="row right-side">
              {CONSTANTS.LABELS.map((label) => {
                let stars = [];
                if (label.rating !== "") {
                  stars = new Array(label.rating).fill(0);
                }

                return (
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="label-box">
                      <img src={label.link} className="img-fluid" />
                      <h3 className="mt-3 mb-3">{label.size}</h3>
                      {label.rating !== "" && (
                        <div className="rating">
                          {stars.map(() => (
                            <i className="fa fa-star"></i>
                          ))}

                          <span>{label.ratingData}</span>
                        </div>
                      )}
                      <p>
                        <strong>{label.label}</strong>
                      </p>
                      <p className="mt-3">{label.note}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row mt-3 bottom-sec">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="temp">
                  <img src="assets/img/temp.jpg" className="img-fluid" />
                  <div className="temp-detail">
                    <h3>Free Templates</h3>
                    <p className="lable">{CONSTANTS.LABEL_FREE_TEMPLATE}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="temp">
                  <img src="assets/img/barcode.jpg" className="img-fluid" />
                  <div className="temp-detail">
                    <h3>Barcode generator</h3>
                    <p className="lable">{CONSTANTS.LABEL_BARCODE}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Labels;
