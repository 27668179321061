import { CircularProgress, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetCategoryWisePoster } from "../../../api/allServices";
import PageHeader from "../../../components/page-header";
import NoItemFound from "../../../components/Ui/no-item-found";
import { CONSTANTS } from "../../../utils/CONSTANTS";
import Poster from "./poster";

export default function AllPosters() {
  const { category } = useParams();
  const [posterList, setPosterList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");



  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await GetCategoryWisePoster(CONSTANTS.API_NAME[category]);
        if (CONSTANTS.RESPONSE_STATUS[res?.data?.status]) {
          console.log(res);
          setPosterList(res?.data?.data?.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader
        destination="Poster"
        heading={"All Poster"}
        subDestination={category + " Posters"}
      />
      {isLoading && (
        <LinearProgress color="inherit" sx={{ margin: "0px 10px" }} />
      )}

      <div class="col-sm-12 col-md-6 mt-3 ms-2">
        <div id="mytable_filter" class="dataTables_filter">
          <label>
            Search:
            <input
              type="search"
              class="form-control form-control-sm"
              placeholder=""
              onChange={(e) => setSearch(e.target.value)}
              aria-controls="mytable"
            />
          </label>
        </div>
      </div>

      <div class="container-fluid py-4">
        <div class="row" style={{ rowGap: "14px" }}>
          {!isLoading && posterList.length == 0 && (
            <NoItemFound message="No poster Found" />
          )}
          {!isLoading &&
            posterList.length != 0 &&
            posterList.filter(poster => search == "" || poster.heading.toLowerCase().includes(search.toLowerCase())).map((poster) => (
              <Poster
                heading={poster.heading}
                isDownload={poster.isDownload}
                src={poster.image}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
