import React from "react";
import NavbarSubListItem from "./navbar-sub-list-Item";

export default function NavbarItem({ menuItem }) {
  if (!menuItem.subList || menuItem.subList.length === 0) {
    return (
      <li className="nav-item">
        <NavbarSubListItem item={menuItem.heading} />
      </li>
    );
  }
  return (
    <li className="nav-item">
      <p className="nav-link">
        <span className="nav-link-text ms-1">{menuItem.heading}</span>
      </p>
      {menuItem.subList.length > 0 && (
        <ul className="submenulist">
          {menuItem.subList.map((item) => (
            <li key={"submenu" + item.id} className="nav-subItem">
              <NavbarSubListItem item={item} />
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}

NavbarItem.defaultProps = {
  menuItem: {
    heading: "heading",
    subList: [],
  },
};
