import moment from "moment";
import Card from "../../../components/Home/card/card";
import ProgressCard from "../../../components/Home/progressCard/progressCard";
import "./dashboardRoutes.css";

const District = ({ analyticsData, totalSell, timeInterVal, startDate, endDate }) => {
  const cards = analyticsData;

  return (
    <>
      <div className="col-xl-12 col-sm-12 mb-xl-0 mb-4">
        <div className="dashboard_hello_text">
          <p className="lable">Happy {moment(new Date()).local().format("dddd")},</p>
          <p className="lable">
            Here’s a snapshot of how you’re doing this week at the District
            level
          </p>
        </div>
      </div>
      <div className="row flex-wrap align-items-center">
        <ProgressCard totalSell={totalSell} timeInterVal={timeInterVal} startDate={startDate} endDate={endDate} />
        {cards.map((card, index) => (
          <Card key={index} heading={card.heading} value={card.value} />
        ))}
      </div>
    </>
  );
};

export default District;
