import { Button, Link, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function HandleToken({ isShow = false, onClose, fetchNewToken }) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: "10px",
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={isShow}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Your clover token has been expired, Please click below button to get new.
                </Typography>
                <Box>
                    <Button className="me-3 mt-3" onClick={fetchNewToken} variant="outlined">
                        {"Get Token"}
                    </Button>
                    {/* {!isSingleButton && <Button className="mt-3" onClick={onNegativeChoice} variant="outlined">
              {onNegativeText}
            </Button>} */}
                </Box>
            </Box>
        </Modal>
    )
}