let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export const SET_SHEET_LABEL_LOCATIONS = (num) => {
  arr = [];

  for (let i = 0; i < num; i++) {
    arr[i] = i;
  }
  return arr;
};

export const SHEET_LABEL_LOCATIONS = arr;
export const REDUCER_KEY = "avery";

export const buildLabels = () =>
  SHEET_LABEL_LOCATIONS.reduce((labels, location) => {
    labels[location] = {};
    return labels;
  }, {});

export const findNextLabelLocation = (labelLocation) => {
  const currentIndex = SHEET_LABEL_LOCATIONS.indexOf(labelLocation);

  if (currentIndex < 0 || currentIndex + 1 >= SHEET_LABEL_LOCATIONS.length) {
    return SHEET_LABEL_LOCATIONS[0];
  }

  return SHEET_LABEL_LOCATIONS[currentIndex + 1];
};
