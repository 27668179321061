import React, { useContext, useState } from "react";
import { Services } from "../api/service";
import { NavConstant } from "../store/context";
import { baseURL } from "../utils/urls";

export default function useAPI(url, successNotification) {
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(-2);
  const Context = useContext(NavConstant);

  const apiCall = async (body) => {
    setLoading(true);
    try {
      switch (url.type) {
        case "post":
          setRes(await Services.post(baseURL + url.url, body));
          break;
        case "patch":
          setRes(await Services.patch(baseURL + url.url, body));
          break;
        case "delete":
          setRes(await Services.delete(baseURL + url.url));
          break;
        default: {
          let param = "";
          if (body) {
            for (let [key, value] of Object.entries(body)) {
              param += `${key}=${value}&`;
            }
          }
          setRes(await Services.get(baseURL + url.url));
        }
      }

      if (successNotification) {
        Context.setIsErrorNotification({
          ...successNotification,
        });
      }
    } catch (error) {
      setRes(-1);
      Context.setIsErrorNotification({
        type: false,
        message: error?.response?.data?.message,
      });
    }
    setLoading(false);
  };

  return {
    loading,
    res,
    apiCall: apiCall,
  };
}
