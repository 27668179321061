import { LinearProgress } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetDashboardData } from "../../api/allServices";
import CustomDate from "../../components/Home/customDate/customDate";
import PageHeader from "../../components/page-header";
import { CONSTANTS, USER_DATA, API_ROUTE } from "../../utils/CONSTANTS";
import Agents from "./DashboardRoutes/agents";
import Corporate from "./DashboardRoutes/corporate";
import District from "./DashboardRoutes/district";
import Location from "./DashboardRoutes/location";
import Market from "./DashboardRoutes/market";
import Transaction from "./DashboardRoutes/Transaction";
import "./DashboardRoutes/dashboardRoutes.css";
import { formatNumber } from "../../utils/storage";
import useAPI from "../../CustomeHooks/API";
import { NavConstant } from "../../store/context";

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
export default function Home() {
  const [heading, setHeading] = useState("");
  const [destination, setDestination] = useState("");
  const [sectionId, setSectionId] = useState(1);
  const [timeInterVal, setTimeInterval] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [totalSell, setTotalSell] = useState(0);

  const getMerchant = useAPI(API_ROUTE.getMerchantByEmail);
  const [merchantChanged, setMerchantChanged] = useState(false)
  const navContext = useContext(NavConstant);

  const [startDate, setStartDate] = useState(
    moment(new Date()).local().format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    moment(tomorrow).local().format("YYYY-MM-DD")
  );
  const [analyticsData, setAnalyticsData] = useState([
    {
      heading: CONSTANTS.TOTAL_CUSTOMERS,
      value: "$0",
    },
    {
      heading: CONSTANTS.NEW_CUSTOMERS,
      value: "$0",
    },
    {
      heading: CONSTANTS.RETURN_CUSTOMERS,
      value: "$0",
    },
    {
      heading: CONSTANTS.TOTAL_CASH_DISCOUNTS,
      value: "$0",
    },
    {
      heading: CONSTANTS.TOTAL_MOBILE_COUPONS,
      value: "$0",
    },
    {
      heading: CONSTANTS.NEW_CUSTOMERS_SALES,
      value: "$0",
    },
    {
      heading: CONSTANTS.RETURN_CUSTOMERS_SALES,
      value: "$0",
    },
  ]);
  const { pathname } = useLocation();

  useEffect(() => {
    setSectionId(1);

    CONSTANTS.NAVIGATION_PENAL_MENU[1].subList.map((list) => {
      if (list.path === pathname) {
        let levels = CONSTANTS.lEVEL;
        setHeading(Object.keys(levels)[list.id - 1]);
        setDestination(levels[Object.keys(levels)[list.id - 1]]);
        setSectionId(list.id);
      }
    });
  }, [pathname]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let sDate;
      let eDate;
      eDate = moment(tomorrow).local().format("YYYY-MM-DD");

      if (timeInterVal === "0") {
        sDate = moment(new Date()).local().format("YYYY-MM-DD");
      } else if (timeInterVal === "1") {
        const fistDate = new Date();
        fistDate.setDate(fistDate.getDate() - 7);

        sDate = moment(fistDate).local().format("YYYY-MM-DD");
      } else if (timeInterVal === "2") {
        const fistDate = new Date();
        fistDate.setDate(fistDate.getDate() - 30);
        sDate = moment(fistDate).local().format("YYYY-MM-DD");
      } else {
        sDate = startDate;
        eDate = endDate;
      }
      try {
        const res = await GetDashboardData(sDate, eDate);
        const couponDetails = res[0]?.data?.data?.data.filter(
          (transaction) => transaction?.coupon_id?.code
        );
        console.log(res[0]?.data?.data?.data);
        const getAllTransaction = res[0]?.data?.data?.data;
        const getTransactionAnalytics = res[1]?.data?.data?.data[0];
        const getAllReturnCustomer = res[2]?.data?.data?.data[0];

        console.log(res);
        if (res != -1) {
          setTotalSell(
            getTransactionAnalytics
              ? getTransactionAnalytics?.transaction_total
              : 0
          );
          const infos = [
            {
              heading: CONSTANTS.TOTAL_CUSTOMERS,
              value: getAllTransaction
                ? formatNumber(+getAllTransaction.length)
                : "0",
            },
            {
              heading: CONSTANTS.NEW_CUSTOMERS,
              value:
                getAllTransaction && getAllReturnCustomer
                  ? formatNumber(
                    +getAllTransaction?.length - getAllReturnCustomer?.count
                  )
                  : "0",
            },
            {
              heading: CONSTANTS.RETURN_CUSTOMERS,
              value: getAllReturnCustomer
                ? formatNumber(+getAllReturnCustomer?.count)
                : "0",
            },
            {
              heading: CONSTANTS.TOTAL_CASH_DISCOUNTS,
              value: getTransactionAnalytics
                ? "$" +
                formatNumber(
                  +getTransactionAnalytics?.discount_total.toFixed(2)
                )
                : "$0",
            },
            {
              heading: CONSTANTS?.TOTAL_MOBILE_COUPONS,
              value: couponDetails.length,
            },
            {
              heading: CONSTANTS?.NEW_CUSTOMERS_SALES,
              value: getAllReturnCustomer
                ? "$" + formatNumber(+getAllReturnCustomer?.sales.toFixed(2))
                : "$0",
            },
            {
              heading: CONSTANTS?.RETURN_CUSTOMERS_SALES,
              value: "$0",
            },
          ];
          setAnalyticsData(infos);
        }
      } catch (error) { }
      setIsLoading(false);
    })();
  }, [timeInterVal, startDate, endDate, merchantChanged]);


  useEffect(() => {

    if (USER_DATA?.user && (USER_DATA?.user?.role === "admin" || USER_DATA?.user?.role === "super-admin")) {
      if (navContext.merchantEmail) {
        getMerchant.apiCall({ email: navContext.merchantEmail });
      }
    }

  }, [navContext.merchantEmail, USER_DATA.user]);

  useEffect(() => {

    if (USER_DATA?.user && (USER_DATA?.user?.role === "admin" || USER_DATA?.user?.role === "super-admin")) {
      if (getMerchant.loading === false) {
        setMerchantChanged(!merchantChanged)
      }
    }
  }, [getMerchant.loading, USER_DATA.user]);



  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader destination={destination} heading={heading} />
      {isLoading && (
        <LinearProgress color="inherit" sx={{ margin: "0px 10px" }} />
      )}
      {sectionId !== 6 && (
        <div className="container-fluid mt-4">
          <div className="top_actionbar">
            <div className="row">
              <div className="col-xl-3 col-sm-5 mb-xl-0 mb-4">
                {" "}
                <select
                  className="time_interval_option"
                  id="inputGroupSelect04"
                  defaultChecked={timeInterVal}
                  onChange={(e) => setTimeInterval(e.target.value)}
                >
                  <option value="0">DAILY</option>
                  <option value="1">WEEKLY</option>
                  <option value="2">MONTHLY</option>
                  <option value="3">CUSTOM</option>
                </select>
              </div>
              <div className="col-xl-9 col-sm-7 mb-xl-0 mb-4">
                <p className="reportowner">
                  {USER_DATA.user?.role && (USER_DATA.user?.role === "admin" || USER_DATA.user?.role === "super-admin") ?
                    (USER_DATA.user?.fName + " " + USER_DATA.user?.lName) :
                    (USER_DATA.user?.firstname + " " + USER_DATA.user?.lastname)
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container-fluid ">
        {sectionId !== 6 && timeInterVal === "3" && (
          <CustomDate
            updateSDate={(e) => setStartDate(e.target.value)}
            updateLDate={(e) => setEndDate(e.target.value)}
          />
        )}
        {sectionId === 1 ? (
          <Agents
            analyticsData={analyticsData}
            totalSell={totalSell}
            timeInterVal={timeInterVal}
            startDate={startDate}
            endDate={endDate}
          />
        ) : sectionId === 2 ? (
          <Corporate
            analyticsData={analyticsData}
            totalSell={totalSell}
            timeInterVal={timeInterVal}
            startDate={startDate}
            endDate={endDate}
          />
        ) : sectionId === 3 ? (
          <Market
            analyticsData={analyticsData}
            totalSell={totalSell}
            timeInterVal={timeInterVal}
            startDate={startDate}
            endDate={endDate}
          />
        ) : sectionId === 4 ? (
          <District
            analyticsData={analyticsData}
            totalSell={totalSell}
            timeInterVal={timeInterVal}
            startDate={startDate}
            endDate={endDate}
          />
        ) : sectionId === 5 ? (
          <Location
            analyticsData={analyticsData}
            totalSell={totalSell}
            timeInterVal={timeInterVal}
            startDate={startDate}
            endDate={endDate}
          />
        ) : sectionId === 6 ? (
          <Transaction
            setIsLoading={setIsLoading}
            analyticsData={analyticsData}
            totalSell={totalSell}
            startDate={startDate}
            endDate={endDate}
          />
        ) : null}
      </div>
    </div>
  );
}
