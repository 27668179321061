import React from "react";
import PageHeader from "../../../components/page-header";
import { CONSTANTS } from "../../../utils/CONSTANTS";
import PosterCategory from "./poster-category";
import "./posters.css";

export default function Poster() {
  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader destination="Poster" heading="Cash Discounts" />
      <div className="container-fluid py-4">
        <div className="row">
          {CONSTANTS.POSTER_CATEGORY.map((category) => (
            <PosterCategory key={"Poster" + category.id} {...category} />
          ))}
        </div>
      </div>
    </div>
  );
}
