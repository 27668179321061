import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Label, { BlankInsert, LabelRow } from "./Label";
import { SHEET_LABEL_LOCATIONS, SET_SHEET_LABEL_LOCATIONS } from "./schema";
import PrintMediaQuery from "../PrintMediaQuery";

const getEvenIndices = (array) =>
  Object.keys(array)
    .map(Number)
    .filter((i) => i % 2 === 0);

const SheetLabels = ({
  id,
  selectedLocation,
  selectLocation,
  preview,
  height,
  width,
  LabelInsertComponent = BlankInsert,
  numberOfSheetLocation = 10,
}) => {
  const [sheetLocation, setSheetLocation] = useState(SHEET_LABEL_LOCATIONS);

  useEffect(() => {
    setSheetLocation(SET_SHEET_LABEL_LOCATIONS(numberOfSheetLocation));
  }, [numberOfSheetLocation]);

  return (
    <React.Fragment>
      {getEvenIndices(sheetLocation).map((i) => (
        <LabelRow key={`avery-row-${i}`} height={height}>
          <Label width={width} preview={preview}>
            <LabelInsertComponent sheetId={id} location={sheetLocation[i]} />
          </Label>
          {sheetLocation[i + 1] && (
            <Label width={width} preview={preview}>
              <LabelInsertComponent
                sheetId={id}
                location={sheetLocation[i + 1]}
              />
            </Label>
          )}
        </LabelRow>
      ))}
    </React.Fragment>
  );
};

export const SheetPreview = ({ className = "", style = {}, id, ...props }) => (
  <div
    className={classNames(
      "d-flex flex-wrap ba mv3 printable justify-content-center sheetForm",
      className,
    )}
    id={id}
    style={{
      ...style,
    }}
  >
    <SheetLabels {...props} preview={true} />
  </div>
);

export const Sheet = PrintMediaQuery(null, SheetPreview);

export default Sheet;
