const currentUserData = {};

const STORE = {
  currentUserData: {},
  chatData: {},
  updateChatFlag: false,
};

export const setChatData = () => {
  const previousChat = JSON.parse(localStorage.getItem("chat-details"));
  STORE.chatData = previousChat != null ? previousChat : {};
  // console.log("get Data", JSON.parse(localStorage.getItem("chat-details")));
};

export const saveData = () => {
  localStorage.setItem("chat-details", JSON.stringify(STORE.chatData));
  // console.log("Set Data", JSON.stringify(STORE.chatData));
};

export function formatNumber(x) {
  return x.toLocaleString("en-US", { maximumFractionDigits: 2 });
}

export default STORE;
