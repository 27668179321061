import PageHeader from "../../../components/page-header";
import MobilePic from "../../../assets/img/mobile.png";
import {
  API_ROUTE,
  CONSTANTS,
  NOTIFICATION_MESSAGE,
  USER_DATA,
} from "../../../utils/CONSTANTS";
import "./users.css";
import useAPI from "../../../CustomeHooks/API";
import { useContext, useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { NavConstant } from "../../../store/context";
import { AddUser } from "../../../api/allServices";

const Users = () => {
  const [passwordErrMsg, setPasswordErrorMsg] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const Context = useContext(NavConstant);

  const updatePass = useAPI(
    API_ROUTE.updatePassword,
    NOTIFICATION_MESSAGE.UPDATE_PASS_SUCCESS
  );

  const validatePassword = (e) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[\s])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,}$/;
    let userEnteredValue = e.target.value;
    if (!regex.test(userEnteredValue)) {
      setPasswordErrorMsg(true);
    } else {
      setPasswordErrorMsg(false);
    }
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    let body = {
      ...USER_DATA.user,
      firstname: formData.get("fName"),
      lastname: formData.get("lName"),
      email: formData.get("email"),
      password: formData.get("password"),
      mobile_number: formData.get("mobileNumber"),
      role:
        (formData.get("role").toLowerCase() != "admin" && formData.get("role").toLowerCase() != "super-admin")
          ? formData.get("role").toLowerCase()
          : USER_DATA.user.role,
      businessId: formData.get("business_nickname"),
    };
    delete body["_id"];
    console.log({ body });
    if (!passwordErrMsg) {
      (async () => {
        try {
          const res = await AddUser(body);
          console.log(res);
          Context.setIsErrorNotification({
            type: true,
            message: "User Data Added",
          });
        } catch (error) {
          Context.setIsErrorNotification({
            type: false,
            message: "Something went wrong",
          });
        }
      })();
      e.target.reset();
    }
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const payload = {
      password: formData.get("temporaryPassword"),
      newPassword: formData.get("newPassword"),
    };
    console.log({ payload });
    if (!passwordErrMsg) {
      updatePass.apiCall(payload);
      e.target.reset();
    }
  };

  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader destination="Users" heading={CONSTANTS.SUB_TITLE} />
      {loading && (
        <LinearProgress color="inherit" sx={{ margin: "0px 10px" }} />
      )}

      <div className="container-fluid py-4">
        <div className="hello_text">
          <p class="lable">{CONSTANTS.WELCOME_LINE}</p>
        </div>
        <div className="setup-form">
          <h3 class="lable">Update your Password</h3>
        </div>
        <form onSubmit={handleUpdatePassword}>
          <div className="row">
            <div className="form-group col-lg-4">
              <input
                type="text"
                name="temporaryPassword"
                required
                className="form-control"
                placeholder="Temporary Password"
              />
            </div>
            <div className="form-group col-lg-4">
              <input
                type="text"
                name="newPassword"
                required
                className="form-control"
                placeholder="New Password"
              />
            </div>
          </div>

          <div className="col-md-6 d-flex confirmBtnDiv">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={updatePass.loading}
            >
              {updatePass.loading ? "Password updating..." : "Update Password"}
            </button>
          </div>
        </form>

        <div className="setup-form mt-3">
          <h3>{CONSTANTS.SETUP_USER}</h3>
          <div class="tab">
            <h6>Corporate</h6>
            <h6>Region / Market</h6>
            <h6>District</h6>
          </div>
          <form onSubmit={handleAddUser}>
            <div className="row align-items-center">
              <div className="form-group col-lg-4">
                <input
                  type="email"
                  name="email"
                  required
                  className="form-control"
                  id="inputPassword2"
                  placeholder="Email (User Name): "
                />
              </div>
              <div class="form-group col-lg-4">
                <select
                  className="form-control"
                  id="inputGroupSelect04"
                  name="role"
                  required
                >
                  {CONSTANTS.USER_ROLE.map((role) => (
                    <option value={role} key={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-4">
                <input
                  type="text"
                  name="fName"
                  required
                  className="form-control"
                  placeholder="First name"
                />
              </div>
              <div className="form-group col-lg-4">
                <input
                  type="text"
                  name="lName"
                  required
                  className="form-control"
                  placeholder="Last name"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-4">
                <input
                  type="text"
                  name="business_nickname"
                  className="form-control"
                  placeholder="Business Id (Optional)"
                />
              </div>
              <div className="form-group col-lg-4">
                <input
                  type="password"
                  name="password"
                  required
                  minLength={5}
                  className="form-control"
                  onChange={(e) => {
                    validatePassword(e);
                  }}
                  placeholder="Password:"
                />
                {passwordErrMsg && (
                  <span className=" fs-6 color-orange">
                    Password must have minimum 5 characters with at least one
                    lowercase, one uppercase and a symbol!
                  </span>
                )}
              </div>
            </div>
            <div className="row align-items-center">
              <div className="form-group col-lg-4">
                <input
                  type={"number"}
                  required
                  name="mobileNumber"
                  className="form-control"
                  placeholder="Mobile Number. (2FA)  "
                />
              </div>
              <div className="col-md-6 d-flex confirmBtnDiv">
                <button type="submit" className="btn btn-primary">
                  Confirm
                </button>
              </div>
            </div>
          </form>

          <p className="note-text">{CONSTANTS.TWO_FACTOR_AUTH_NOTICE}</p>
        </div>
      </div>
    </div>
  );
};

export default Users;
