import moment from "moment";
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
const getStartEndDate = (timeInterVal, startDate, endDate) => {
  console.log("object", timeInterVal);
  let sDate;
  let eDate;
  eDate = moment(tomorrow).local().format("YYYY-MM-DD");
  if (timeInterVal == "DAILY") {
    sDate = moment(new Date()).local().format("YYYY-MM-DD");
  } else if (timeInterVal == "WEEKLY") {
    const fistDate = new Date();
    fistDate.setDate(fistDate.getDate() - 7);

    sDate = moment(fistDate).local().format("YYYY-MM-DD");
  } else if (timeInterVal == "MONTHLY") {
    const fistDate = new Date();
    fistDate.setDate(fistDate.getDate() - 30);
    sDate = moment(fistDate).local().format("YYYY-MM-DD");
  } else {
    sDate = startDate;
    eDate = endDate;
  }
  return [
    sDate,
    eDate
  ]
}

export default getStartEndDate;