import React from 'react'


const StartDateFromDate = ({ SelectStartDate, SelectFromDate }) => {
    const [value, setValue] = React.useState(null);
    return (
        <>
            <div className="custome_dates">
                <div className="row">
                    <div className="col-xl-1 col-sm-2 mb-xl-0 mb-4">
                        <p className="date_title">Custom</p>
                    </div>
                    <div className="col-xl-2 col-sm-5 mb-xl-0 mb-4">
                        <div id="datepicker" className="input-group" data-date-format="mm-dd-yyyy">
                            <input className="form-control" type="date" onChange={(e) => SelectStartDate(e)} />
                            <span className="input-group-addon">
                                <i className="fa fa-calendar" aria-hidden="true"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-5 mb-xl-0 mb-4">
                        <div id="datepicker" className="input-group" data-date-format="mm-dd-yyyy">
                            <input className="form-control" type="date" onChange={(e) => SelectFromDate(e)} />
                            <span className="input-group-addon">
                                <i className="fa fa-calendar" aria-hidden="true"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StartDateFromDate