const NameInsert = ({ name }) => {
  let i = 0;
  while (i < 11000000) {
    i += 1;
  }

  return (
    <div className="flex tc h-100 w-100 box printable">
      <span className="printable ">{name}</span>
    </div>
  );
};

export default NameInsert;
