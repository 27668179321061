import React from 'react'

const ActionBar = ({option,value,onChange,title}) => {
  return (
    <div className="top_actionbar">
        <div className="row">
            <div className="col-xl-2 col-sm-5 mb-xl-0 mb-4">
                <select className="time_interval_option" onChange={onChange}>
                    {option && option.length > 0 && option.map((opt,i)=>{
                        return <option selected={value == opt.value} key={i}>{opt.value}</option>
                    })}
                </select>
            </div>
            <div className="col-xl-10 col-sm-7 mb-xl-0 mb-4">
                <p className="reportowner">{title}</p>
            </div>
        </div>
    </div>
  )
}

export default ActionBar