import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    color: "#3c4f69",
  },
  palette: {
    primary: {
      main: "#009bff",
      background: "#009bff",
      borderRadius: "10px",
      padding: "15px",
      color: "#FFFFFF",
      paddingLeft: 40,
      paddingRight: 40,
      marginTop: 2,
      marginBottom: 2,
      "&:hover": {
        background: "#009bff",
      },
    },
    background: {
      main: "#009bff",
      menuHover: "#142E6C",
      buttonBackgroundDark: "#69798f",
    },
    icons: {
      main: "#59bad1",
      hover: "#3c4f69",
    },
    text: {
      mainLableBold: "#009bff",
      white: "#fff",
      sideBarLink: "#F2F3F5",
      info: "#0048FF",
      danger: "#E21A00",
      warning: "#FD8E00",
      success: "#07C180",
    },
    input: {
      borderMain: "#F4F4F4",
      textMain: "#000000",
      labelMain: "#717171",
      background: "#F4F4F4",
    },
    table: {
      headBackground: "#3c4f69",
      evenRow: "#f7f7f7",
      textMain: "#3c4f69",
      rowHover: "#f0fcff",
    },
    modal: {
      headerBackgroundColor: "#3c4f69",
      headerColor: "white",
    },
  },
});

export default theme;
